export interface WebshopOrder {
  id: number;
  created_at: string;
  comment: string;
  status: number;
  customer_id: string;
  zip?: number;
  city?: string;
  street?: string;
  street_nr?: string;
  address2?: string;
  webshop_order_item: WebshopOrderItem[];
  trash: boolean;
  substitute: boolean;
  generic: boolean;
  probon: boolean;
  webshop_order_status?: WebshopOrderStatus;
  children: WebshopOrder[];
  parent: number;
  delivery_method: number;
  delivery_nr: string;
}

// Define the interface for webshop_order_item
export interface WebshopOrderItem {
  id: number;
  pharmacode: number;
  order_id: number;
  amount: number;
  webshop_order_item_status: undefined | OrderItemStatus;
}

export interface WebshopOrderStatus {
  id: number;

  ftp_upload: boolean;
}

interface OrderStatus {
  name: string;
  color: string;
  progress?: number;
}

interface OrderItemStatus {
  order_id: number;
  controlled: boolean;
  by: string;
}

export const webshopOrderStatus: { [key: number]: OrderStatus } = {
  0: { name: "offen", color: "blue", progress: 0 },
  1: { name: "in Bearbeitung", color: "orange", progress: 1 },
  2: { name: "zur Kontrolle", color: "orange", progress: 2 },
  98: { name: "versendet", color: "green", progress: 3 },
  4: { name: "Nota", color: "yellow", progress: 5 },
  99: { name: "storniert", color: "red", progress: 4 },
  100: { name: "angeliefert", color: "green", progress: 3 },
  3: { name: "kontrolliert", color: "yellow", progress: 5 },
};

export interface WebshopOrderItemWithOrderData extends WebshopOrderItem {
  status: number;
  created_at: string;
  orderId: number;
}
