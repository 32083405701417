import { supabase } from "@/helpers/supabase";
import { useNotificationStore } from "@/store/notificationStore";

export class CareProviderGroupRequests {
  async addToCareProviderGroup(
    careProviderGroupId: number,
    userId: string,
    publicKeyId: number,
    encryptedPrivateKeyIds: number[],
    encryptedPrivateKeys: string[]
  ): Promise<boolean> {
    try {
      const addToCareProviderGroup = await supabase.rpc(
        "invite_to_care_provider_group",
        {
          _cpg_id: careProviderGroupId,
          _user_id: userId,
          _used_public_key_id: publicKeyId,
          _encrypted_keys: encryptedPrivateKeys,
          _encrypted_key_ids: encryptedPrivateKeyIds,
        }
      );
      if (addToCareProviderGroup.error) {
        useNotificationStore().notifications.push({
          msg: `Fehler beim Hinzufügen des Mitarbeiters. Bitte laden Sie die Seite neu und versuchen Sie es erneut. (Fehler: ${JSON.stringify(
            addToCareProviderGroup.error
          )})`,
          type: "danger",
        });
      }

      return true;
    } catch (e) {
      console.error("Fehler beim Hinzufügen eines Mitarbeiters:", e);
      return false;
    }
  }

  async removeFromCareProviderGroup(
    careProviderGroupId: number,
    userId: string
  ): Promise<boolean> {
    try {
      const result = await supabase
        .from("careProvider_careProviderGroup")
        .delete()
        .eq("care_provider_group_id", careProviderGroupId)
        .eq("care_provider_id", userId);

      if (result.error != null) {
        console.error("Error removing CP", result.error);
        return false;
      }

      return true;
    } catch (e) {
      console.error("WHAT?", e);
      return false;
    }
  }

  async changeOwnerOfCareProviderGroup(
    careProviderGroupId: number,
    userId: string
  ): Promise<boolean> {
    try {
      const result = await supabase
        .from("careProviderGroup")
        .update([
          {
            owner: userId,
          },
        ])
        .eq("id", careProviderGroupId);

      if (result.error != null) {
        console.error("Error changing owner of CPG", result.error);
        return false;
      }

      return true;
    } catch (e) {
      console.error("WHAT?", e);
      return false;
    }
  }
}
