import _sodium, { CryptoKX } from "libsodium-wrappers-sumo";
import { CareProviderGroupKey } from "@/interfaces/careProviderGroup";
import { useAccountStore } from "@/store/accountStore";
import { useNotificationStore } from "@/store/notificationStore";
import { usePublicStore } from "@/store/publicStore";
import { useSessionStore } from "@/store/sessionStore";

/* ENCRYPTION INTERFACES */

export interface EncryptedResult {
  encryptedMsg: Uint8Array;
  nonce: Uint8Array;
  serverKeyId: number;
  clientKeyId: number;
}

export interface EncryptionObj {
  encryptedMessage: string; // as hex-String
  nonce: string; // as hex-String
  serverCareProviderGroupId: number;
  clientCareProviderGroupId: number;
  creatorCareProviderGroupId: number;
  serverKeyId: number;
  clientKeyId: number;
}

export interface DecryptedObject {
  message: string; // as hex-String
  serverCareProviderGroupId: number;
  clientCareProviderGroupId: number;
  creatorCareProviderGroupId: number;
}

export interface SymmetricEncryptionObj {
  encryptedMessage: string; // as hex-String
  nonce: string; // as hex-String
}

/* SHARED FUNCTIONS */

// Convert a hex string to a byte array
export function hexToBytes(hexString: string): Uint8Array {
  const bytes: number[] = [];
  const hex = hexString.replace("\\x", "");
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }
  return Uint8Array.from(bytes);
}

// Convert a byte array to a hex string
export function bytesToHex(bytes: Uint8Array): string {
  const hex: string[] = [];
  for (let i = 0; i < bytes.length; i++) {
    const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
    hex.push((current >>> 4).toString(16));
    hex.push((current & 0xf).toString(16));
  }
  return "\\x" + hex.join("");
}

// Encode text to UTF-8 byte array
function encodeToUtf8(text: string): Uint8Array {
  const encoder = new TextEncoder();
  return encoder.encode(text);
}

// Decode UTF-8 byte array to text
function decodeUtf8(bytes: Uint8Array): string {
  const decoder = new TextDecoder("utf-8");
  return decoder.decode(bytes);
}

// Generates random Salt
export async function generateRandomSalt(): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  return sodium.randombytes_buf(_sodium.crypto_pwhash_SALTBYTES);
}

/* ASYMMETRIC ENCRYPTION */

// Convert a byte array to a hex string
export async function passwordToKeyPair(
  password: string,
  saltAsHex: string
): Promise<_sodium.KeyPair> {
  await _sodium.ready;
  const sodium = _sodium;
  const pwhash = sodium.crypto_pwhash(
    32,
    encodeToUtf8(password),
    hexToBytes(saltAsHex),
    sodium.crypto_pwhash_OPSLIMIT_MODERATE,
    sodium.crypto_pwhash_MEMLIMIT_MODERATE,
    sodium.crypto_pwhash_ALG_DEFAULT
  );

  const key = sodium.crypto_kx_seed_keypair(pwhash);

  return key;
}

export async function createKeyPair(): Promise<_sodium.KeyPair> {
  await _sodium.ready;
  const sodium = _sodium;

  const key = sodium.crypto_kx_keypair();
  return key;
}

// Convert a byte array to a hex string
export async function createClientSessionKey(
  clientPublicKey: Uint8Array,
  clientSecretKey: Uint8Array,
  serverPublicKey: Uint8Array
): Promise<CryptoKX> {
  await _sodium.ready;
  const sodium = _sodium;

  const key = sodium.crypto_kx_client_session_keys(
    clientPublicKey,
    clientSecretKey,
    serverPublicKey
  );
  return key;
}

// Convert a byte array to a hex string
export async function createServerSessionKey(
  serverPublicKey: Uint8Array,
  serverSecretKey: Uint8Array,
  clientPublicKey: Uint8Array
): Promise<CryptoKX> {
  await _sodium.ready;
  const sodium = _sodium;

  const key = sodium.crypto_kx_server_session_keys(
    serverPublicKey,
    serverSecretKey,
    clientPublicKey
  );

  return key;
}

export async function encryptAsClient(
  clientPublicKey: CareProviderGroupKey,
  clientSecretKey: CareProviderGroupKey,
  serverPublicKey: CareProviderGroupKey,
  msg: string | Uint8Array
): Promise<EncryptedResult> {
  await _sodium.ready;
  const sodium = _sodium;
  const clientKX = await createClientSessionKey(
    clientPublicKey.key,
    clientSecretKey.key,
    serverPublicKey.key
  );

  const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
  const encryptedMsg = sodium.crypto_secretbox_easy(
    typeof msg == "string" ? encodeToUtf8(msg) : msg,
    nonce,
    clientKX.sharedTx
  );

  return {
    encryptedMsg: encryptedMsg,
    nonce: nonce,
    serverKeyId: serverPublicKey.keyId,
    clientKeyId: clientPublicKey.keyId,
  } as EncryptedResult;
}

export async function encryptAsServer(
  serverPublicKey: CareProviderGroupKey,
  serverSecretKey: CareProviderGroupKey,
  clientPublicKey: CareProviderGroupKey,
  msg: string | Uint8Array
): Promise<EncryptedResult> {
  await _sodium.ready;
  const sodium = _sodium;

  const serverKX = await createServerSessionKey(
    serverPublicKey.key,
    serverSecretKey.key,
    clientPublicKey.key
  );

  const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
  const encryptedMsg = sodium.crypto_secretbox_easy(
    typeof msg == "string" ? encodeToUtf8(msg) : msg,
    nonce,
    serverKX.sharedTx
  );

  return {
    encryptedMsg: encryptedMsg,
    nonce: nonce,
    clientKeyId: clientPublicKey.keyId,
    serverKeyId: serverPublicKey.keyId,
  };
}

// Convert a byte array to a hex string
export async function decryptBytesAsClient(
  clientKX: CryptoKX,
  encrypted_msg: Uint8Array,
  nonce: Uint8Array,
  isOwnMessage: boolean
): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  const decryptedMsg = sodium.crypto_secretbox_open_easy(
    encrypted_msg,
    nonce,
    isOwnMessage ? clientKX.sharedTx : clientKX.sharedRx
  );

  return decryptedMsg;
}
// Convert a byte array to a hex string
export async function decryptAsClient(
  clientKX: CryptoKX,
  encrypted_msg: Uint8Array,
  nonce: Uint8Array,
  isOwnMessage: boolean
): Promise<string> {
  const decryptedMsg = decodeUtf8(
    await decryptBytesAsClient(clientKX, encrypted_msg, nonce, isOwnMessage)
  );

  return decryptedMsg;
}

// Convert a byte array to a hex string
export async function decryptBytesAsServer(
  serverKX: CryptoKX,
  encrypted_msg: Uint8Array,
  nonce: Uint8Array,
  isOwnMessage: boolean
): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  const decryptedMsg = sodium.crypto_secretbox_open_easy(
    encrypted_msg,
    nonce,
    isOwnMessage ? serverKX.sharedTx : serverKX.sharedRx
  );

  return decryptedMsg;
}
// Convert a byte array to a hex string
export async function decryptAsServer(
  serverKX: CryptoKX,
  encrypted_msg: Uint8Array,
  nonce: Uint8Array,
  isOwnMessage: boolean
): Promise<string> {
  const decryptedMsg = decodeUtf8(
    await decryptBytesAsServer(serverKX, encrypted_msg, nonce, isOwnMessage)
  );

  return decryptedMsg;
}

export async function encryptCareProviderGroupKeyForCareProviderGroup(
  careProviderPublicKey: Uint8Array,
  careProviderGroupPrivateKey: Uint8Array
): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  try {
    const encryptedSecretKey = sodium.crypto_box_seal(
      careProviderGroupPrivateKey,
      careProviderPublicKey
    );
    return encryptedSecretKey;
  } catch (e) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Unternehmensschlüssel konnte nicht verschlüsselt werden. #e01: e`,
      type: "danger",
    });
    throw e;
  }
}

export async function decryptCareProviderGroupKey(
  careProviderPublicKey: Uint8Array,
  careProviderSecretKey: Uint8Array,
  encryptedCareProviderGroupPrivateKey: Uint8Array
): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  try {
    const decryptedSecretKey = sodium.crypto_box_seal_open(
      encryptedCareProviderGroupPrivateKey,
      careProviderPublicKey,
      careProviderSecretKey
    );
    return decryptedSecretKey;
  } catch (e) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Unternehmensschlüssel konnte nicht entschlüsselt werden. #e02: e`,
      type: "danger",
    });

    throw e;
  }
}

export async function decryptEncryptedMsg(
  encryptionObj: EncryptionObj
): Promise<string> {
  const publicStore = usePublicStore();
  const accountStore = useAccountStore();
  const sessionStore = useSessionStore();

  if (sessionStore.selectedCareProviderGroup == null) return "ERROR no CPG";
  const serverPublicKey = publicStore.careProviderGroupKeys?.get(
    encryptionObj.serverKeyId
  )?.key;
  const clientPublicKey = publicStore.careProviderGroupKeys?.get(
    encryptionObj.clientKeyId
  )?.key;

  const privateKey =
    encryptionObj.serverCareProviderGroupId ==
    sessionStore.selectedCareProviderGroupId
      ? accountStore.careProviderGroupPrivateKeys?.get(
          encryptionObj.serverKeyId
        )
      : accountStore.careProviderGroupPrivateKeys?.get(
          encryptionObj.clientKeyId
        );

  if (
    serverPublicKey != null &&
    clientPublicKey != null &&
    privateKey != null
  ) {
    if (
      sessionStore.selectedCareProviderGroup.id ==
      encryptionObj.clientCareProviderGroupId
    ) {
      const clientSessionKey = await createClientSessionKey(
        clientPublicKey,
        privateKey.key,
        serverPublicKey
      );

      return await decryptAsClient(
        clientSessionKey,
        hexToBytes(encryptionObj.encryptedMessage),
        hexToBytes(encryptionObj.nonce),
        encryptionObj.creatorCareProviderGroupId ==
          sessionStore.selectedCareProviderGroup.id
      );
    } else {
      const serverSessionKey = await createServerSessionKey(
        serverPublicKey,
        privateKey.key,
        clientPublicKey
      );

      return await decryptAsServer(
        serverSessionKey,
        hexToBytes(encryptionObj.encryptedMessage),
        hexToBytes(encryptionObj.nonce),
        encryptionObj.creatorCareProviderGroupId ==
          sessionStore.selectedCareProviderGroup.id
      );
    }
  } else {
    console.error(
      "MISSING KEY: ",
      serverPublicKey,
      clientPublicKey,
      privateKey
    );
    return "MISSING KEY";
  }
}

export async function encryptMsg(
  decryptedObject: DecryptedObject
): Promise<EncryptedResult> {
  const publicStore = usePublicStore();
  const accountStore = useAccountStore();
  const sessionStore = useSessionStore();

  if (sessionStore.selectedCareProviderGroup == null) throw Error("NO CPG");
  const senderPublicKey = publicStore.latestPublicKeyOfGroup(
    decryptedObject.clientCareProviderGroupId
  );
  const receiverPublicKey = publicStore.latestPublicKeyOfGroup(
    decryptedObject.serverCareProviderGroupId
  );

  const privateKey = sessionStore.getCurrentPrivateKey();

  if (
    senderPublicKey != null &&
    privateKey != null &&
    receiverPublicKey != null
  ) {
    if (
      sessionStore.selectedCareProviderGroup.id ==
      decryptedObject.clientCareProviderGroupId
    ) {
      return await encryptAsClient(
        senderPublicKey,
        privateKey,
        receiverPublicKey,
        encodeToUtf8(decryptedObject.message)
      );
    } else {
      return await encryptAsServer(
        senderPublicKey,
        privateKey,
        receiverPublicKey,
        encodeToUtf8(decryptedObject.message)
      );
    }
  } else {
    throw Error("ERROR ENCRYPTING");
  }
}

/* SYMMETRIC ENCRYPTION (Patients) */

export async function getSymmetricKey(): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  return sodium.crypto_secretbox_keygen();
}

export async function encryptSymmetric(
  message: string | Uint8Array,
  key: Uint8Array,
  setNonce?: Uint8Array
): Promise<SymmetricEncryptionObj> {
  await _sodium.ready;
  const sodium = _sodium;
  const nonce = sodium.randombytes_buf(sodium.crypto_box_NONCEBYTES);
  return {
    encryptedMessage: bytesToHex(
      await sodium.crypto_secretbox_easy(
        typeof message == "string" ? encodeToUtf8(message) : message,
        setNonce ?? nonce,
        key
      )
    ),
    nonce: bytesToHex(setNonce ?? nonce),
  };
}

export async function decryptSymmetric(
  message: string | Uint8Array,
  nonce: Uint8Array,
  key: Uint8Array
): Promise<string> {
  return decodeUtf8(await decryptSymmetricBytes(message, nonce, key));
}

export async function decryptSymmetricBytes(
  message: string | Uint8Array,
  nonce: Uint8Array,
  key: Uint8Array
): Promise<Uint8Array> {
  await _sodium.ready;
  const sodium = _sodium;
  return await sodium.crypto_secretbox_open_easy(
    typeof message == "string" ? hexToBytes(message) : message,
    nonce,
    key
  );
}
