import { getDateFromDaysSince1900 } from "./datehelper";

interface PatientData {
  firstName: string;
  lastName: string;
  ssn: string;
  sex: boolean;
  birthday: Date;
}

interface MedicationData {
  ssn: string;
  dscrd: string;
  gtin: number;
}

interface Medication {
  pharmacode: number;
  dscrd: string;
  gtin: number;
  createdAt: string;
  supplierId: number | null;
  isInIndex: boolean;
}

export interface PatientWithMedications {
  profile: PatientData;
  medications: Array<Medication>;
}

export function processBulkCSVFiles(
  patientsCSVFile: File,
  medicationsCSVFile: File
): Promise<Array<PatientWithMedications>> {
  return new Promise((resolve, reject) => {
    const patientsData: Array<PatientData> = [];
    const medicationsData: Array<MedicationData> = [];

    const processPatientsCSV = (fileContent: string) => {
      // Verarbeiten Sie die Patientendaten und füllen Sie patientsData.
      const simplifiedFileContent = fileContent.replace("\r", "");
      const lines = simplifiedFileContent.split("\n");
      const columns = lines[0].split(";");
      const firstNameColumn = columns.indexOf("Vorname");
      const lastNameColumn = columns.indexOf("Name");
      const ssnColumn = columns.indexOf("SVN");
      const salutationColumn = columns.indexOf("Anrede");
      const birthdayColumn = columns.indexOf("Geburtsdatum");
      const minColumns = Math.max(
        ...[
          firstNameColumn,
          lastNameColumn,
          ssnColumn,
          salutationColumn,
          birthdayColumn,
        ]
      );
      if (firstNameColumn == -1) {
        reject(Error("Vorname fehlt in der Patienten-CSV"));
      }
      if (lastNameColumn == -1) {
        reject(Error("Nachname fehlt in der Patienten-CSV"));
      }
      if (ssnColumn == -1) {
        reject(Error("Sozialversicherungsnummer fehlt in der Patienten-CSV"));
      }
      if (salutationColumn == -1) {
        reject(Error("Anrede fehlt in der Patienten-CSV"));
      }
      if (birthdayColumn == -1) {
        reject(Error("Geburtsdatum fehlt in der Patienten-CSV"));
      }
      for (let i = 1; i < lines.length; i++) {
        const fields = lines[i].split(";");
        if (fields.length <= minColumns) {
          continue;
        }

        patientsData.push({
          firstName: fields[firstNameColumn],
          lastName: fields[lastNameColumn],
          ssn: fields[ssnColumn],
          sex: fields[salutationColumn] == "Frau",
          birthday: getDateFromDaysSince1900(parseInt(fields[birthdayColumn])),
        });
      }
    };

    const processMedicationsCSV = (fileContent: string) => {
      const simplifiedFileContent = fileContent.replace("\r", "");

      const lines = simplifiedFileContent.split("\n");
      const columns = lines[0].split(";");
      const ssnColumn = columns.indexOf("AHV Patient");
      const medicationColumn = columns.indexOf("Medikament");
      const eanColumn = columns.indexOf("EAN-Code");

      const minColumns = Math.max(...[ssnColumn, medicationColumn, eanColumn]);
      if (medicationColumn == -1) {
        reject(Error("Medikamentenspalte fehlt in der Meikamenten-CSV"));
      }
      if (eanColumn == -1) {
        reject(Error("EAN fehlt in der Medikamenten-CSV"));
      }
      if (ssnColumn == -1) {
        reject(
          Error("Sozialversicherungsnummer fehlt in der Medikamenten-CSV")
        );
      }

      for (let i = 1; i < lines.length; i++) {
        const fields = lines[i].split(";");
        if (fields.length <= minColumns) {
          continue;
        }

        medicationsData.push({
          ssn: fields[ssnColumn],
          dscrd: fields[medicationColumn],
          gtin: parseInt(fields[eanColumn]),
        });
      }
    };

    const patientFileReader = new FileReader();
    const medicationFileReader = new FileReader();

    patientFileReader.onload = (event) => {
      const result = event.target?.result as string;
      processPatientsCSV(result);
    };
    medicationFileReader.onload = (event) => {
      const result = event.target?.result as string;

      processMedicationsCSV(result);
    };
    patientFileReader.onloadend = () => {
      medicationFileReader.readAsText(medicationsCSVFile);
    };
    medicationFileReader.onloadend = () => {
      const patientMedicationMap = new Map<string, Array<Medication>>();

      patientsData.forEach((patient) => {
        const medicationsForPatient: Array<Medication> = [];
        const has = new Set<number>();
        medicationsData.forEach((medication) => {
          if (medication.ssn === patient.ssn && !has.has(medication.gtin)) {
            medicationsForPatient.push({
              pharmacode: -1,
              createdAt: `${new Date().getTime()}-${Math.round(
                Math.random() * 100000
              )}`,
              dscrd: medication.dscrd,
              gtin: medication.gtin,
              supplierId: null,
              isInIndex: true,
            });
            has.add(medication.gtin);
          }
        });

        patientMedicationMap.set(patient.ssn, medicationsForPatient);
      });

      const resultArray: Array<PatientWithMedications> = [];

      patientsData.forEach((patient) => {
        const medications = patientMedicationMap.get(patient.ssn) || [];
        resultArray.push({
          profile: patient,
          medications: medications,
        });
      });

      resolve(resultArray);
    };

    patientFileReader.readAsText(patientsCSVFile);
  });
}
