export function getColor() {
  const color =
    "hsl(" +
    Math.round(360 * Math.random() * 10) / 10 +
    "," +
    Math.round((25 + 70 * Math.random()) * 10) / 10 +
    "%," +
    Math.round((85 + 10 * Math.random()) * 10) / 10 +
    "%)";
  return color;
}
