import { PublicProfileKey } from "@/interfaces/publicProfileKey";
import { supabase } from "@/helpers/supabase";
import { usePublicStore } from "@/store/publicStore";

export class PublicDataRequests {
  async currentPublicKeyForProfile(
    userId: string
  ): Promise<PublicProfileKey | null> {
    try {
      const result = await supabase
        .from("profile_publicKey")
        .select("id, public_key")
        .eq("user_id", userId)
        .order("id", { ascending: false })
        .limit(1)

        .returns<PublicProfileKey>()
        .single();
      if (result.error != null) {
        console.error("ERROR LOADING (Public) CPs ", result.error);
        return null;
      }

      return result.data;
    } catch (e) {
      console.error("WHAT?", e);
      return null;
    }
  }
}

export function nameFromUid(uid: string | null) {
  if (uid == null) return "unb.";
  const profile = profileFromUid(uid);
  if (profile == null) {
    return "unb.";
  } else {
    return `${profile.first_name} ${profile.last_name}`;
  }
}

export function profileFromUid(uid: string | null) {
  if (uid == null) return null;

  const publicStore = usePublicStore();

  const profile = publicStore.profiles?.find((profile) => profile.id == uid);
  return profile;
}
