import { Icon } from "@/assets/icons/icons";
import { defineStore } from "pinia";

interface Notification {
  msg: string;
  type: "warning" | "danger" | "success" | "info";
  icon?: Icon;
  action?: () => Promise<void>;
  customId?: string;
  keepAfterPageChange?: boolean;
  keepFor?: number;
}

interface NotificationState {
  notifications: Notification[];
}

export const useNotificationStore = defineStore("notification", {
  state: (): NotificationState => ({
    notifications: [],
  }),
  actions: {
    removeNotificationsWithId(id: string) {
      this.notifications = this.notifications.filter(
        (notification) => notification.customId != id
      );
    },
  },
});
