import { Customer } from "../interfaces/customer";
import {
  WebshopOrder,
  WebshopOrderItemWithOrderData,
} from "../interfaces/order";

export function statusOfOrder(order: WebshopOrder): number {
  if (order.status == 2 && isControlled(order)) {
    return 3;
  }
  return order.status;
}

export function isControlled(order: WebshopOrder) {
  return (
    order.webshop_order_item.filter(
      (i) => i.webshop_order_item_status?.controlled != true
    ).length === 0
  );
}

export function customerString(customer: Customer | undefined | null) {
  if (!customer) return "unb.";
  const customerData = [];
  if (customer.gender) {
    customerData.push(`${["", "♂", "♀", "⚧"][customer.gender ?? 0]}`);
  }
  if (customer.birthday) {
    customerData.push(`${new Date(customer.birthday).toLocaleDateString()}`);
  }

  const customerInsurance = latestCustomerInsurance(customer);
  if (customerInsurance) {
    customerData.push(`${customerInsurance.veka_nr}`);
  }
  if (customer.language) {
    customerData.push(`${customer.language == "fr" ? "🇫🇷" : "🇩🇪"}`);
  }

  return `${customer.first_name} ${customer.last_name} (${customerData.join(
    ", "
  )})`;
}

export function latestCustomerInsurance(customer: Customer | undefined) {
  if (
    customer?.webshop_customer_insurance &&
    customer.webshop_customer_insurance.length > 0
  ) {
    return [...customer.webshop_customer_insurance].sort((a, b) => {
      return b.id - a.id;
    })[0];
  }
  return undefined;
}

export function latestCustomerAddress(customer: Customer | undefined) {
  if (
    customer?.webshop_customer_address &&
    customer.webshop_customer_address.length > 0
  ) {
    return [...customer.webshop_customer_address].sort((a, b) => {
      return b.id - a.id;
    })[0];
  }
  return undefined;
}

export function latestCustomerQuestionnaire(customer: Customer | undefined) {
  if (
    customer?.webshop_customer_questionnaire &&
    customer.webshop_customer_questionnaire.length > 0
  ) {
    return [...customer.webshop_customer_questionnaire].sort((a, b) => {
      return b.id - a.id;
    })[0];
  }
  return undefined;
}

export function latestCustomerOrderDefaults(customer: Customer | undefined) {
  if (
    customer?.webshop_customer_order_defaults &&
    customer.webshop_customer_order_defaults.length > 0
  ) {
    return [...customer.webshop_customer_order_defaults].sort((a, b) => {
      return b.id - a.id;
    })[0];
  }
  return undefined;
}

export function ordersOfItems(
  orders: WebshopOrder[],
  customerId: string,
  pharmacode: number
) {
  const filteredOrders = orders.filter((o) => o.customer_id == customerId);
  const items: WebshopOrderItemWithOrderData[] = [];
  filteredOrders.forEach((o) => {
    const orderStatus = statusOfOrder(o);
    o.webshop_order_item.forEach((i) => {
      if (i.pharmacode == pharmacode) {
        items.push({
          ...i,
          orderId: o.id,
          status: orderStatus,
          created_at: o.created_at,
        });
      }
    });
  });
  return items.sort((a, b) => b.id - a.id);
}
