import { supabase } from "@/helpers/supabase";
import { RealtimeChannel } from "@supabase/supabase-js";
import { decryptEncryptedMsg, EncryptionObj } from "@/helpers/encryption";
import { Conversation } from "@/interfaces/conversation";
import { useSessionStore } from "./sessionStore";
import { Message, BoxWithOrders } from "@/interfaces/message";
import { MessageType } from "@/interfaces/enum/messageType";
import { defineStore } from "pinia";
import { CareProviderGroup } from "@/interfaces/careProviderGroup";
import { useMedicationsStore } from "./medicationsStore";
import { BaseMedication } from "@/interfaces/medication";

interface OrdersState {
  conversations: Map<number, Conversation> | null;
  messages: Map<number, Message> | null;
  realTimeConversationChannel: RealtimeChannel | null;
  realTimeMessagesChannel: RealtimeChannel | null;
}

export const useOrdersStore = defineStore("orders", {
  state: (): OrdersState => ({
    conversations: null,
    messages: null,
    realTimeConversationChannel: null,
    realTimeMessagesChannel: null,
  }),
  getters: {
    allReceivers() {
      const careProviderGroups: Map<number, CareProviderGroup> = new Map();
      for (const conversation of this.conversations?.values() ?? []) {
        careProviderGroups.set(
          conversation.receiverCareProviderGroup.id,
          conversation.receiverCareProviderGroup
        );
        careProviderGroups.set(
          conversation.senderCareProviderGroup.id,
          conversation.senderCareProviderGroup
        );
      }
      const myCpg = useSessionStore().selectedCareProviderGroup;
      if (myCpg != null) {
        careProviderGroups.delete(myCpg.id);
      }
      return careProviderGroups;
    },
    organizedBoxes(state): Array<BoxWithOrders> {
      const boxes: BoxWithOrders[] = [];
      const allMessages = Array.from(state.messages?.values() ?? []);
      for (const message of allMessages) {
        const boxStatus = message.medication_box?.status ?? 0;
        if (
          message.type == MessageType.box &&
          message.medication_box?.deleted != true
        ) {
          const orders = allMessages.filter(
            (msg) =>
              msg.medication_order?.box == message.id &&
              !msg.medication_order?.deleted
          );
          let folder = 0;
          if (boxStatus == 1) {
            folder = 1;
          }
          if (boxStatus == 2 || boxStatus == 3) {
            folder = 2;
          }

          const deliveryStatus = allMessages.findLast(
            (msg) =>
              msg.type == MessageType.deliveryStatus &&
              msg.conversation.id == message.conversation.id
          );

          const fridge = orders.filter(
            (order) => order.medication_order?.storage_location == 1
          );
          const safe = orders.filter(
            (order) => order.medication_order?.storage_location == 2
          );

          const box = {
            ...message,
            orders: orders,
            fridge: fridge,
            safe: safe,
            folder: folder,
            due:
              message.medication_box?.date ??
              deliveryStatus?.delivery_status?.date,
          };

          boxes.push(box);
        }
      }

      return boxes;
    },
    getMessages: (state) => {
      return (conversationId: number): Message[] => {
        const allMessagesAsArray: Message[] = Array.from(
          state.messages?.values() ?? []
        );
        let allMessagesForGivenConversationId: Message[] = [];
        if (state.messages) {
          allMessagesForGivenConversationId = allMessagesAsArray.filter(
            (msg) => msg.conversation.id == conversationId
          );
        }
        return allMessagesForGivenConversationId;
      };
    },
  },
  actions: {
    addConversation(conversation: Conversation) {
      this.conversations?.set(conversation.id, conversation);
    },
    addMessage(message: Message) {
      this.messages?.set(message.id, message);
    },
    async updateMessages(messageId?: number, conversationId?: number) {
      const sessionStore = useSessionStore();

      let messagesRequest = supabase
        .from("orders_message")
        .select(
          `
          nonce,
          id,
          encrypted_message,
          created_by,
          created_at,
          type,
          sender_care_provider_group_id,
          conversation: orders_conversation!inner (
            receiver_care_provider_group_id,
            sender_care_provider_group_id,
            id
          ),
          server_key_id,
          client_key_id
        `
        )
        .or(
          `sender_care_provider_group_id.eq.${
            sessionStore.selectedCareProviderGroup?.id ?? 0
          }, receiver_care_provider_group_id.eq.${
            sessionStore.selectedCareProviderGroup?.id ?? 0
          }`,
          { foreignTable: "orders_conversation" }
        )
        .order("created_at", { ascending: true });
      if (messageId) {
        messagesRequest = messagesRequest.eq("id", messageId);
      }
      if (conversationId) {
        messagesRequest = messagesRequest.eq("conversation_id", conversationId);
      }
      const messagesResult = await messagesRequest.returns<Message[]>();

      if (messagesResult.data != null) {
        const messagesMap = new Map<number, Message>();
        for (const message of messagesResult.data?.filter(
          (c) => c.conversation != null
        ) ?? []) {
          messagesMap.set(message.id, message);
        }
        const messages = await this.decryptMessages(messagesMap);
        if (!this.messages) this.messages = new Map();
        messages.forEach((msg) => {
          this.messages?.set(msg.id, msg);
        });
      }
    },
    async updateConversations(conversationId?: number) {
      const sessionStore = useSessionStore();
      let conversationsRequest = supabase
        .from("orders_conversation")
        .select(
          `
      encrypted_subject,
      nonce,
      id,
      created_at,
      receiverCareProviderGroup: receiver_care_provider_group_id(
        *
      ),
      senderCareProviderGroup: sender_care_provider_group_id(
        *
      ),
      orders_sent(
        created_at,
        created_by
      ),
      orders_conversation_status(
        *
      ),
      server_key_id,
      client_key_id
    `
        )

        .or(
          `sender_care_provider_group_id.eq.${
            sessionStore.selectedCareProviderGroup?.id ?? 0
          }, receiver_care_provider_group_id.eq.${
            sessionStore.selectedCareProviderGroup?.id ?? 0
          }`
        )
        .or(
          `care_provider_group_id.eq.${
            sessionStore.selectedCareProviderGroup?.id ?? 0
          }`,
          { foreignTable: "orders_conversation_status" }
        );
      if (conversationId) {
        conversationsRequest = conversationsRequest.eq("id", conversationId);
      }

      const conversationsResult = await conversationsRequest.returns<
        Conversation[]
      >();

      if (conversationsResult.data != null) {
        const conversationsMap = new Map<number, Conversation>();

        for (const conversation of conversationsResult.data ?? []) {
          conversation.archived =
            conversation.orders_conversation_status[0]?.archived == true;
          conversation.read =
            conversation.orders_conversation_status[0]?.read_message_id;
          if (
            conversation.orders_sent ||
            conversation.senderCareProviderGroup.id ==
              sessionStore.selectedCareProviderGroup?.id
          ) {
            conversationsMap.set(conversation.id, conversation);
          }
        }
        await this.decryptConversations(conversationsMap);
      }
    },
    async loadHistory(messageId: number) {
      const messagesResult = await supabase
        .from("orders_message")
        .select(
          `
          nonce,
          id,
          encrypted_message,
          created_by,
          created_at,
          type,
          sender_care_provider_group_id,
          conversation: orders_conversation (
            receiver_care_provider_group_id,
            sender_care_provider_group_id,
            id
          ),
          server_key_id,
          client_key_id
        `
        )
        .eq("id", messageId)
        .returns<Message[]>();

      if (messagesResult.data != null) {
        const messagesMap = new Map<number, Message>(
          messagesResult.data.map<[number, Message]>((Message, index) => [
            index,
            Message,
          ])
        );
        return (
          Array.from((await this.decryptMessages(messagesMap)).values()) ?? []
        );
      }
      return [];
    },

    async decryptConversations(conversations: Map<number, Conversation>) {
      if (!this.conversations) this.conversations = new Map();
      for (const i of conversations.keys()) {
        const conversation = conversations.get(i);
        if (conversation != null) {
          await this.decryptConversation(conversation);
          this.conversations?.set(conversation.id, conversation);
        }
      }
    },
    async decryptConversation(conversation: Conversation) {
      try {
        conversation.decrypted_subject =
          this.conversations?.get(conversation.id)?.decrypted_subject ??
          (await decryptEncryptedMsg({
            encryptedMessage: conversation.encrypted_subject,
            clientCareProviderGroupId: conversation.senderCareProviderGroup.id,
            serverCareProviderGroupId:
              conversation.receiverCareProviderGroup.id,
            nonce: conversation.nonce,
            creatorCareProviderGroupId: conversation.senderCareProviderGroup.id,
            clientKeyId: conversation.client_key_id,
            serverKeyId: conversation.server_key_id,
          } as EncryptionObj));
      } catch (e) {
        console.error(e, conversation);
      }
    },
    async decryptMessages(messages: Map<number, Message>) {
      for (const i of messages.keys()) {
        const message = messages.get(i);
        if (message != null) {
          try {
            message.decrypted_message = await decryptEncryptedMsg({
              encryptedMessage: message.encrypted_message,
              clientCareProviderGroupId:
                message.conversation.sender_care_provider_group_id,
              serverCareProviderGroupId:
                message.conversation.receiver_care_provider_group_id,
              nonce: message.nonce,
              creatorCareProviderGroupId: message.sender_care_provider_group_id,
              clientKeyId: message.client_key_id,
              serverKeyId: message.server_key_id,
            } as EncryptionObj);
            if (
              message.type == MessageType.order &&
              message.decrypted_message != null
            ) {
              message.medication_order = JSON.parse(message.decrypted_message);
            }
            if (
              message.type == MessageType.box &&
              message.decrypted_message != null
            ) {
              message.medication_box = JSON.parse(message.decrypted_message);
            }
            if (
              message.type == MessageType.deliveryStatus &&
              message.decrypted_message != null
            ) {
              message.delivery_status = JSON.parse(message.decrypted_message);
            }
          } catch (e) {
            console.error(e, message);
          }
        }
      }

      return messages;
    },
    async fetchMedicationsInformation(conversationId: number) {
      const medicationsStore = useMedicationsStore();
      if (this.messages) {
        const conversationSpecificMessages = Array.from(
          this.messages.values()
        ).filter((message) => message.conversation.id == conversationId);
        const medicationsFromMessages: BaseMedication[] = [];
        const receiverCpgIdOfGivenConversation =
          this.conversations?.get(conversationId)?.receiverCareProviderGroup
            ?.id;
        for (const message of conversationSpecificMessages) {
          if (
            message.medication_order?.gtin !== 0 &&
            message.medication_order
          ) {
            medicationsFromMessages.push(message.medication_order);
          }
        }
        await medicationsStore.fetchMedicationsInformation(
          medicationsFromMessages,
          receiverCpgIdOfGivenConversation
        );
      }
    },
  },
});
