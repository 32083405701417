import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "./formkit/formkit.config";
import { createPinia } from "pinia";
import VersionDisplay from "@/components/VersionDisplay.vue";
import VueMatomo from "vue-matomo";

declare global {
  interface Window {
    _paq: any;
  }
}

const pinia = createPinia();
const app = createApp(App);
app.use(plugin, defaultConfig(formkitConfig));
app.use(router);
app.use(pinia);
app.component("VersionDisplay", VersionDisplay);

app.use(VueMatomo, {
  host: "https://espaceehealth.matomo.cloud/",
  siteId: 2,
  trackerFileName: "matomo",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: "cockpit.medibe.ch",
});

app.mount("#app");
