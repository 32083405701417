export type Icon =
  | "pen"
  | "plus"
  | "left"
  | "down"
  | "archive"
  | "close"
  | "eye"
  | "changeLog"
  | "menu"
  | "trash"
  | "more"
  | "minus"
  | "scan-barcode"
  | "pdf"
  | "refresh"
  | "check"
  | "checkbox-yes"
  | "checkbox-no"
  | "user"
  | "download"
  | "cart"
  | "user-change"
  | "fold"
  | "mail"
  | "right"
  | "star"
  | "star-filled";

export const getIcon = (icon: Icon) => {
  switch (icon) {
    case "pen":
      return require("@/assets/icons/icon=pen, outlined=false.svg");
    case "plus":
      return require("@/assets/icons/icon=plus, outlined=false.svg");
    case "left":
      return require("@/assets/icons/icon=arrow-right, outlined=false.svg");
    case "down":
      return require("@/assets/icons/icon=arrow-down, outlined=false.svg");
    case "archive":
      return require("@/assets/icons/icon=archive, outlined=false.svg");
    case "refresh":
      return require("@/assets/icons/icon=refresh, outlined=false.svg");
    case "eye":
      return require("@/assets/icons/icon=eye, outlined=false.svg");
    case "close":
      return require("@/assets/icons/icon=close, outlined=false.svg");
    case "right":
      return require("@/assets/icons/icon=arrow-left, outlined=false.svg");
    case "changeLog":
      return require("@/assets/icons/icon=change-log, outlined=false.svg");
    case "trash":
      return require("@/assets/icons/icon=delete, outlined=true.svg");
    case "more":
      return require("@/assets/icons/icon=left, outlined=false.svg");
    case "menu":
      return require("@/assets/icons/icon=menu, outlined=false.svg");
    case "minus":
      return require("@/assets/icons/icon=minus, outlined=false.svg");
    case "pdf":
      return require("@/assets/icons/icon=pdf, outlined=false.svg");
    case "check":
      return require("@/assets/icons/icon=check, outlined=false.svg");
    case "scan-barcode":
      return require("@/assets/icons/icon=scan-barcode, outlined=false.svg");
    case "checkbox-yes":
      return require("@/assets/icons/icon=check-box-true, outlined=false.svg");
    case "checkbox-no":
      return require("@/assets/icons/icon=check-box-false, outlined=false.svg");
    case "user":
      return require("@/assets/icons/single-neutral-actions-1.svg");
    case "download":
      return require("@/assets/icons/icon=import, outlined=false.svg");
    case "cart":
      return require("@/assets/icons/icon=cart, outlined=false.svg");
    case "user-change":
      return require("@/assets/icons/single-neutral-actions-refresh.svg");
    case "fold":
      return require("@/assets/icons/icon=down, outlined=false.svg");
    case "star":
      return require("@/assets/icons/rating-star.svg");
    case "star-filled":
      return require("@/assets/icons/rating-star-filled.svg");
    case "mail":
      return require("@/assets/icons/mail.svg");
  }
  return require("@/assets/icons/icon=pen, outlined=false.svg"); // throws an error if this line is missing
};
