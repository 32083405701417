import { defineStore } from "pinia";
import { Session } from "@supabase/supabase-js";
import { KeyPair } from "libsodium-wrappers";
import {
  bytesToHex,
  decryptEncryptedMsg,
  passwordToKeyPair,
} from "@/helpers/encryption";
import {
  CareProviderGroup,
  CareProviderGroupKey,
} from "@/interfaces/careProviderGroup";
import { useOrdersStore } from "./ordersStore";
import { useManagedPatientsStore } from "./managedPatientsStore";
import { useRealTimeSubscriptionStore } from "./realTimeSubscriptionStore";
import { supabase } from "@/helpers/supabase";
import { useAccountStore } from "./accountStore";
import { usePublicStore } from "./publicStore";
import { useNotificationStore } from "./notificationStore";
import { Medication } from "@/interfaces/medication";
import { useMedicationsStore } from "./medicationsStore";

interface SessionState {
  session: Session | null;
  keyPair: KeyPair | null;
  password: string | null;
  selectedCareProviderGroupId: number | null;
  selectedCareProviderGroupKeys: Map<number, CareProviderGroupKey>;
  isInitialLoadFinished: boolean;
  salt: string | null;
  correctPublicKey: string | null;
  publicKeyId: number | null;
  ftp: {
    host: string;
    username: string;
    password: string;
    articleListFileName: string;
  } | null;
  favorites: Medication[] | null;
}

export const useSessionStore = defineStore("session", {
  state: (): SessionState => ({
    session: null,
    keyPair: null,
    password: null,
    selectedCareProviderGroupId: null,
    isInitialLoadFinished: false,
    salt: null,
    correctPublicKey: null,
    publicKeyId: null,
    ftp: null,
    selectedCareProviderGroupKeys: new Map(),
    favorites: null,
  }),
  getters: {
    selectedCareProviderGroup(): CareProviderGroup | null {
      return (
        usePublicStore().careProviderGroups?.find(
          (cpg) => cpg.id == this.selectedCareProviderGroupId
        ) ?? null
      );
    },
  },
  actions: {
    async calculateKeyPair(
      password: string,
      salt: string,
      correctPublicKey: string,
      savePassword?: boolean
    ) {
      const calculatedKeyPair = await passwordToKeyPair(password, salt);

      if (
        calculatedKeyPair?.publicKey != null &&
        bytesToHex(calculatedKeyPair?.publicKey) == correctPublicKey
      ) {
        this.keyPair = calculatedKeyPair;
        if (savePassword) {
          sessionStorage.setItem(
            "med_key",
            JSON.stringify({
              keyType: calculatedKeyPair.keyType,
              privateKey: bytesToHex(calculatedKeyPair.privateKey),
              publicKey: bytesToHex(calculatedKeyPair.publicKey),
            })
          );
        }
      } else {
        useNotificationStore().notifications.push({
          msg: `Passwort ist falsch`,
          type: "danger",
        });
      }
    },
    async setSelectedCareProviderGroup(
      selectedCareProviderGroup: CareProviderGroup
    ) {
      const ordersStore = useOrdersStore();
      const managedPatientsStore = useManagedPatientsStore();

      if (
        this.selectedCareProviderGroup != null &&
        this.selectedCareProviderGroup.id != selectedCareProviderGroup.id
      ) {
        alert(
          "WARNING: CareProviderGroup is not intended to change. Reload the page."
        );
        location.reload();
      }
      this.selectedCareProviderGroupId = selectedCareProviderGroup.id;
      const futures = [
        ordersStore.updateConversations(),
        ordersStore.updateMessages(),
        managedPatientsStore.updatePatients(),
        this.loadFtpSettings(),
        this.loadFavorites(),
      ];
      for (const entry of useAccountStore().careProviderGroupPrivateKeys ??
        []) {
        if (entry[1].cpgId == selectedCareProviderGroup.id) {
          this.selectedCareProviderGroupKeys.set(entry[0], entry[1]);
        }
      }
      useRealTimeSubscriptionStore().startSubscription();

      await Promise.all(futures);

      this.isInitialLoadFinished = true;
      return;
    },
    getCurrentPrivateKey() {
      return this.selectedCareProviderGroupKeys.get(
        Math.max(...this.selectedCareProviderGroupKeys.keys())
      );
    },
    getCurrentPublicKey() {
      return usePublicStore().careProviderGroupKeys?.get(
        Math.max(...this.selectedCareProviderGroupKeys.keys())
      );
    },
    async loadFtpSettings() {
      if (!this.selectedCareProviderGroup) return;
      const result = await supabase
        .from("careProviderGroup_ftp")
        .select(
          `
        nonce,
        encrypted_connection,
        used_key
      `
        )
        .eq("care_provider_group_id", this.selectedCareProviderGroup.id)
        .limit(1)
        .maybeSingle();
      if (!result.data) return;

      if (this.keyPair != null && result.data != null) {
        this.ftp = JSON.parse(
          await decryptEncryptedMsg({
            encryptedMessage: result.data.encrypted_connection,
            nonce: result.data.nonce,
            serverCareProviderGroupId: this.selectedCareProviderGroup.id,
            clientCareProviderGroupId: this.selectedCareProviderGroup.id,
            creatorCareProviderGroupId: this.selectedCareProviderGroup.id,
            clientKeyId: result.data.used_key,
            serverKeyId: result.data.used_key,
          })
        );
      }
    },
    async loadFavorites() {
      if (!this.selectedCareProviderGroup) return;
      const result = await supabase
        .from("careProviderGroup_favorites")
        .select(
          `
        nonce,
        encrypted_favorites,
        used_key
      `
        )
        .eq("care_provider_group_id", this.selectedCareProviderGroup.id)
        .limit(1)
        .maybeSingle();
      if (!result.data) {
        this.favorites = [];
        return;
      }

      if (this.keyPair != null && result.data != null) {
        this.favorites = JSON.parse(
          await decryptEncryptedMsg({
            encryptedMessage: result.data.encrypted_favorites,
            nonce: result.data.nonce,
            serverCareProviderGroupId: this.selectedCareProviderGroup.id,
            clientCareProviderGroupId: this.selectedCareProviderGroup.id,
            creatorCareProviderGroupId: this.selectedCareProviderGroup.id,
            clientKeyId: result.data.used_key,
            serverKeyId: result.data.used_key,
          })
        );
      }
    },
    async fetchMedicationsInformation(cpgId: number | null) {
      const medicationsStore = useMedicationsStore();
      if (this.favorites) {
        await medicationsStore.fetchMedicationsInformation(
          this.favorites,
          cpgId
        );
      }
    },
  },
});
