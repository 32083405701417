import { BaseMedication, Medication } from "@/interfaces/medication";
import { supabase } from "@/helpers/supabase";

interface RequestBody {
  searchArg: string | number | BaseMedication[];
  searchBy: "string" | "pharmacode" | "gtin" | "string*" | "pharmacodeOrGtin";
  careProviderGroupId: number | null | undefined;
}

export async function searchForMedicationsByString(
  searchString: string,
  shopCareProviderGroupId: number | null | undefined
): Promise<{
  data: { medications: Medication[]; searchString: string } | null;
  error: any;
}> {
  if (searchString.length < 4) {
    const errorMessage =
      "ERROR: Should not search for medications with less than 4 chars";
    console.error(errorMessage);
    return { data: null, error: errorMessage };
  }

  let requestBody: RequestBody;

  if (searchString.slice(-1) == "*") {
    requestBody = {
      searchArg: searchString.split("*")[0],
      searchBy: "string*",
      careProviderGroupId: shopCareProviderGroupId,
    };
  } else {
    requestBody = {
      searchArg: searchString,
      searchBy: "string",
      careProviderGroupId: shopCareProviderGroupId,
    };
  }

  const { data, error }: { data: Medication[] | null; error: any } =
    await supabase.functions.invoke("fetchMedicationInformation", {
      body: requestBody,
    });

  return data == null
    ? { data: null, error: error }
    : { data: { medications: data, searchString }, error: error };
}

export async function findMedicationWithGtin(
  gtin: number,
  shopCareProviderGroupId: number | null | undefined
): Promise<{ data: Medication | null; error: any }> {
  const requestBody: RequestBody = {
    searchArg: gtin,
    searchBy: "gtin",
    careProviderGroupId: shopCareProviderGroupId,
  };

  const { data, error }: { data: Medication | null; error: any } =
    await supabase.functions.invoke("fetchMedicationInformation", {
      body: requestBody,
    });

  return { data: data, error: error };
}

export async function findMedicationWithPharmacode(
  pharmacode: number,
  shopCareProviderGroupId?: number | null | undefined
): Promise<{ data: Medication | null; error: any }> {
  const requestBody: RequestBody = {
    searchArg: pharmacode,
    searchBy: "pharmacode",
    careProviderGroupId: shopCareProviderGroupId,
  };

  const { data, error }: { data: Medication | null; error: any } =
    await supabase.functions.invoke("fetchMedicationInformation", {
      body: requestBody,
    });

  return { data: data, error: error };
}

export async function findMedicationsWithPharmacodeOrGtin(
  medications: BaseMedication[],
  shopCareProviderGroupId: number | null | undefined
): Promise<{ data: Medication[] | null; error: any }> {
  const requestBody: RequestBody = {
    searchArg: JSON.stringify(medications),
    searchBy: "pharmacodeOrGtin",
    careProviderGroupId: shopCareProviderGroupId,
  };

  const { data, error }: { data: Medication[] | null; error: any } =
    await supabase.functions.invoke("fetchMedicationInformation", {
      body: requestBody,
    });

  return { data: data, error: error };
}

export async function findPharmIndexMedicationWithPharmacode(
  pharmacode: number
): Promise<{ data: Medication | null; error: any }> {
  const { data, error }: { data: Medication | null; error: any } =
    await supabase
      .from("pharmindex")
      .select("*, pharmindex_images ( * )")
      .eq("pharmacode", pharmacode)
      .maybeSingle();

  return { data: data, error: error };
}

export async function findPharmIndexMedicationWithGtin(
  gtin: number
): Promise<{ data: Medication | null; error: any }> {
  const { data, error }: { data: Medication | null; error: any } =
    await supabase
      .from("pharmindex")
      .select("*, pharmindex_images ( * )")
      .eq("gtin", gtin)
      .maybeSingle();

  return { data: data, error: error };
}

export async function findPharmIndexMedicationWithDscrd(
  searchString: string
): Promise<{
  data: { medications: Medication[]; searchString: string } | null;
  error: any;
}> {
  const { data, error }: { data: Medication[] | null; error: any } =
    await supabase
      .from("pharmindex")
      .select("*, pharmindex_images ( * )")
      .ilike("dscrd", `${searchString.split(" ").join("%")}%`);

  return { data: { medications: data ?? [], searchString }, error: error };
}
