import { ddmmyyyDate } from "@/helpers/datehelper";
import { WebshopOrder } from "../interfaces/order";
import { Customer } from "../interfaces/customer";
import { useWebshopStore } from "../store/webshopStore";
import { latestCustomerAddress, latestCustomerInsurance } from "./orderHelper";

export function buildWebshopCsv(
  customer: Customer,
  order: WebshopOrder
): string {
  const kkNumber = latestCustomerInsurance(customer)?.veka_nr ?? "";
  const birthday = ddmmyyyDate(customer.birthday);
  let text = "";
  const paymentType = 3;
  const webshopStore = useWebshopStore();
  const receiverAddress = order.street;
  const receiverAddress2 = "";
  const receiverZip = order.zip;
  const receiverCity = order.city;
  const address = latestCustomerAddress(customer);
  const random = Math.floor(Math.random() * 100);

  text += `NN_001||${customer.last_name}|${customer.first_name}||${
    address?.street ?? ""
  }|${address?.zip ?? ""}|${
    address?.city ?? ""
  }|Schweiz|CH|||||${kkNumber}|${birthday}`;
  text += `\n`;
  text += `NN_002|${customer.last_name}|${customer.first_name}||${receiverAddress} ${receiverAddress2}|${receiverZip}|${receiverCity}|Schweiz|CH|`;
  text += `\n`;
  text += `NN_003|w${order.id}k${random}|CHF|${paymentType}|||`;
  for (const medication_order of order.webshop_order_item) {
    const pharmacode = medication_order.pharmacode;
    const name =
      webshopStore.medications[medication_order.pharmacode]?.dscrd ?? "unb.";
    text += `\n`;
    text += `NN_004|${pharmacode}|${name}|1||1|999.0||via umana importiert: ${name} und pharmacode ${medication_order.pharmacode}`;
  }

  return text;
}
