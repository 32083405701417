import { useManagedPatientsStore } from "@/store/managedPatientsStore";
import { localeDateString } from "./datehelper";

export function patientString(patientId: number): string {
  try {
    if (patientId == 0) {
      return "Verbrauchsmaterial";
    } else {
      const patient = useManagedPatientsStore().patients?.find(
        (patient) => patient.id == patientId
      )?.currentProfile;
      if (patient == null) {
        return `Nicht verknüpfter Patient #${patientId}`;
      }
      const patientParts = [
        `${patient.firstName ?? ""} ${patient.lastName ?? ""}`,
      ];
      if (patient.birthday) {
        patientParts.push(localeDateString(patient.birthday));
      }

      return patientParts.join(", ");
    }
  } catch (e) {
    return "Fehler. Patient nicht verknüpft (pid#" + patientId + ")";
  }
}
