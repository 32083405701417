import { supabase } from "@/helpers/supabase";
import { Profile } from "@/interfaces/profile";

import { defineStore } from "pinia";
import { useSessionStore } from "./sessionStore";

interface CareProviderGroupStore {
  connectedProfiles: Profile[] | null;
}

export const useCareProviderGroupStore = defineStore("careProviderGroup", {
  state: (): CareProviderGroupStore => ({
    connectedProfiles: null,
  }),
  getters: {},

  actions: {
    async updateConnectedProfiles() {
      const sessionStore = useSessionStore();

      const connectedProfileIdsResult = await supabase
        .from("careProvider_careProviderGroup_distinct")
        .select(
          `
          care_provider_id,
          profile(
            *
          )
        `
        )
        .eq(
          "care_provider_group_id",
          sessionStore.selectedCareProviderGroup?.id
        )
        .returns<{ care_provider_id: number; profile: Profile }[]>();

      if (connectedProfileIdsResult.data) {
        const profiles = connectedProfileIdsResult.data.map(
          (m) => m.profile as Profile
        );
        this.connectedProfiles = profiles;
      } else {
        console.error("ERROR", connectedProfileIdsResult.error);
      }
    },
  },
});
