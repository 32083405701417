import { supabase } from "@/helpers/supabase";
import { defineStore } from "pinia";
import { Customer } from "../interfaces/customer";
import { useNotificationStore } from "@/store/notificationStore";
import { WebshopOrder } from "../interfaces/order";

export interface WebshopState {
  customers: Customer[] | null;
  orders: WebshopOrder[] | null;
  medications: any[];
}

export const useWebshopStore = defineStore("webshop", {
  state: (): WebshopState => ({
    customers: null,
    orders: null,
    medications: [],
  }),
  getters: {
    receivers(state) {
      const receivers = new Set<string>();
      state.orders?.forEach((o) => receivers.add(o.customer_id));
      return receivers;
    },
  },
  actions: {
    async loadCustomers() {
      const customersResult = await supabase
        .from("webshop_customers_view")
        .select(
          "*, webshop_customer_medication ( * ),webshop_customer_order_defaults ( * ), webshop_customer_questionnaire ( * ), webshop_customer_insurance (*), webshop_customer_address (*)"
        )
        .returns<Customer[]>();
      if (customersResult.data == null) {
        useNotificationStore().notifications.push({
          msg: `Fehler - Keine Kundendaten gefunden`,
          type: "danger",
        });
        return;
      }
      this.customers = customersResult.data;
    },
    async loadOrders() {
      const orderFields =
        "*, webshop_order_status ( * ), webshop_order_item ( *, webshop_order_item_status ( * ) )";
      const ordersResult = await supabase
        .from("webshop_order")
        .select(`${orderFields}, children:webshop_order (${orderFields})`)
        .returns<WebshopOrder[]>();
      if (ordersResult.data == null) {
        useNotificationStore().notifications.push({
          msg: `Fehler - Keine Bestellungen gefunden`,
          type: "danger",
        });
        return;
      }
      this.orders = ordersResult.data.sort((a, b) => b.id - a.id);
    },
  },
});
