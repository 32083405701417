import {
  findMedicationWithGtin,
  findMedicationWithPharmacode,
  findMedicationsWithPharmacodeOrGtin,
} from "@/helpers/requestHelpers/medicationRequests";
import { BaseMedication } from "@/interfaces/medication";
import { defineStore } from "pinia";
import { ref } from "vue";
import { supabase } from "@/helpers/supabase";
import { useSessionStore } from "@/store/sessionStore";

export const useMedicationsStore = defineStore("medications", () => {
  /**
   * The key of the map is a string representing the id of a care provider group concatenated with the delimiter '-' and the pharmacode of a medication.
   * e.g. 1-3415167
   */
  const cachedMedications = ref(new Map<string, BaseMedication>());

  async function fetchMedicationInformation(
    medication: BaseMedication | null | undefined,
    cpgId: number | null | undefined,
    ignoreCache?: boolean
  ) {
    if (!medication || !cpgId) return;

    // Check if medication is already loaded
    let foundMedication: BaseMedication | null | undefined = null;
    if (!ignoreCache) {
      foundMedication = findMedicationInCache(medication.pharmacode, cpgId);
      if (foundMedication) {
        updateMedicationInformation(medication, foundMedication, true);
        return;
      }
    }

    // If medication is not already loaded fetch it from db
    const res = await findMedicationWithPharmacode(
      medication.pharmacode,
      cpgId
    );
    foundMedication = res.data;

    // Makes sure backward compatability is guaranteed because in previous versions gtin was used to identify medications.
    if (!foundMedication && medication.gtin) {
      const res = await findMedicationWithGtin(medication.gtin, cpgId);
      foundMedication = res.data;
    }

    updateMedicationInformation(medication, foundMedication);
    addMedicationToCache(medication, cpgId);
  }

  async function fetchMedicationsInformation(
    medications: BaseMedication[] | null | undefined,
    cpgId: number | null | undefined
  ) {
    if (!medications || medications.length === 0 || !cpgId) return;

    const medicationsToFetch: BaseMedication[] = [];

    for (const medication of medications.filter((m) => m.gtin !== 0)) {
      const cachedMedication = findMedicationInCache(
        medication.pharmacode,
        cpgId
      );

      if (cachedMedication) {
        updateMedicationInformation(medication, cachedMedication, true);
      } else {
        medicationsToFetch.push(medication);
      }
    }

    if (medicationsToFetch.length !== 0) {
      const res = await findMedicationsWithPharmacodeOrGtin(
        medicationsToFetch,
        cpgId
      );
      if (res.error) {
        throw res.error;
      }
      const fetchedMedications = res.data;

      for (const medication of medicationsToFetch) {
        updateMedicationInformation(
          medication,
          fetchedMedications?.find(
            (fm) =>
              fm.pharmacode === medication.pharmacode ||
              fm.gtin === medication.gtin
          )
        );
        addMedicationToCache(medication, cpgId);
      }
    }
  }

  function findMedicationInCache(
    pharmacode: number,
    cpgId: number
  ): BaseMedication | null | undefined {
    return cachedMedications.value.get(`${cpgId}-${pharmacode}`);
  }

  function addMedicationToCache(
    medication: BaseMedication,
    cpgId: number | null | undefined
  ) {
    if (cpgId && medication.pharmacode && medication.gtin !== 0) {
      cachedMedications.value.set(
        `${cpgId}-${medication.pharmacode}`,
        medication
      );
    }
  }

  function updateMedicationInformation(
    medication: BaseMedication,
    updatedMedication: BaseMedication | null | undefined,
    medicationInformationLoadedFromCache?: boolean
  ) {
    if (updatedMedication) {
      medication.public_price = updatedMedication.public_price;
      medication.storage_quantity = updatedMedication.storage_quantity;

      // Makes sure backward compatability is guaranteed
      medication.pharmacode = updatedMedication.pharmacode;
      medication.nincd = updatedMedication.nincd;
      medication.cool_storage = updatedMedication.cool_storage;

      if (!medicationInformationLoadedFromCache) {
        medication.isInIndex = true;
      }
    } else {
      medication.isInIndex = false;
    }
  }

  async function updateShopMedicationInformation(forceUpdate = false) {
    const sessionStore = useSessionStore();
    // Check if ftp settings are available
    const ftp = sessionStore.ftp;
    if (
      !(
        ftp?.host &&
        ftp?.username &&
        ftp?.password &&
        ftp?.articleListFileName &&
        sessionStore.selectedCareProviderGroup
      )
    ) {
      return;
    }

    // Get last update time from db
    const { data, error } = await supabase
      .from("shop_medication_information")
      .select("updated_at")
      .eq("care_provider_group_id", sessionStore.selectedCareProviderGroup?.id)
      .limit(1)
      .returns<{ updated_at: string }[]>();

    if (error) {
      console.error(error);
      return;
    }

    // Update shop_medication_information
    if (
      forceUpdate ||
      data.length === 0 ||
      Date.now() - new Date(data[0].updated_at).getTime() > 12 * 1000 * 60 * 60
    ) {
      await supabase.functions.invoke("updateShopMedicationInformation", {
        body: {
          host: ftp.host,
          username: ftp.username,
          password: ftp.password,
          careProviderGroupId: sessionStore.selectedCareProviderGroup.id,
          articleListFileName: ftp.articleListFileName,
        } as {
          host: string;
          username: string;
          password: string;
          careProviderGroupId: number;
          articleListFileName: string;
        },
      });
    }
  }

  function reset() {
    cachedMedications.value.clear();
  }

  return {
    updateShopMedicationInformation,
    fetchMedicationInformation,
    fetchMedicationsInformation,
    reset,
  };
});
