export function nincdToString(nincd: number | null | undefined) {
  switch (nincd) {
    case 10:
      return `SL ${nincd}`;
    case 30:
      return `NLP ${nincd}`;
    case 31:
      return `NLP ${nincd}`;
    case 16:
      return `ALT ${nincd}`;
    case 13:
      return `MiGeL ${nincd}`;
    default:
      return "";
  }
}

export function nincdToLongString(nincd: number | null | undefined) {
  const string = nincdToString(nincd);
  if (string) {
    return "Liste: " + string;
  }
  return "Liste: k.A.";
}
