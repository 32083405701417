export interface Weekday {
  id: number;
  name: string;
}

export const Weekdays: Array<Weekday> = [
  { name: "Sonntag", id: 0 },
  { name: "Montag", id: 1 },
  { name: "Dienstag", id: 2 },
  { name: "Mittwoch", id: 3 },
  { name: "Donnerstag", id: 4 },
  { name: "Freitag", id: 5 },
  { name: "Samstag", id: 6 },
];
