import { CareProviderGroup } from "@/interfaces/careProviderGroup";
import { ManagedPatient } from "@/interfaces/managedPatient";
import { Message } from "@/interfaces/message";
import { ddmmyyyDate } from "./datehelper";

export function buildCsvFromSortedOrders(
  conversationId: number,
  sortedOrders: Map<number, Message[]>,
  patients: Map<number, ManagedPatient>,
  medicationReceiver: CareProviderGroup
): string[] {
  let i = 0;
  const orderLines: string[] = [];
  for (const patientOrder of sortedOrders) {
    const patient = patients.get(patientOrder[0]);
    const orders = patientOrder[1];

    if (orders.length == 0) continue;
    const kkNumber = patient?.currentProfile?.veka ?? "";
    const birthday = ddmmyyyDate(patient?.currentProfile?.birthday);
    let text = "";
    let paymentType = 3;

    const receiverAddress = medicationReceiver.address1;
    const receiverAddress2 = medicationReceiver.address2;
    const receiverZip = medicationReceiver.zip;
    const receiverCity = medicationReceiver.city;
    const random = Math.floor(Math.random() * 100);

    if (patientOrder[0] !== 0) {
      text += `NN_001|${patient?.id}|${
        patient?.currentProfile?.lastName ?? "unb."
      }|${
        patient?.currentProfile?.firstName ?? "unb."
      }||${receiverAddress}|${receiverZip}|${receiverCity}|Schweiz|CH|||||${kkNumber}|${birthday}`;
    } else {
      paymentType = 1;
      text += `NN_001|${medicationReceiver.propharma_id ?? ""}|(${
        medicationReceiver.id
      })|${
        medicationReceiver.group_name
      }||${receiverAddress} ${receiverAddress2}|${receiverZip}|${receiverCity}|Schweiz|CH||||`;
    }

    text += "\n";
    text += `NN_002|||${medicationReceiver.group_name}|${receiverAddress} ${receiverAddress2}|${receiverZip}|${receiverCity}|Schweiz|CH|`;

    text += `\n`;
    text += `NN_003|u${conversationId}i${i}k${random}|CHF|${paymentType}|||`;
    for (const drugOrder of orders) {
      if (!drugOrder.medication_order) continue;
      const pharmacode = drugOrder.medication_order.pharmacode;
      text += `\n`;
      text += `NN_004|${pharmacode ? pharmacode : ""}|${
        drugOrder.medication_order?.name
      }|${drugOrder.medication_order?.amount}||1|999.0||via umana importiert: ${
        drugOrder.medication_order.name
      } und pharmacode ${drugOrder.medication_order.pharmacode}`;
    }
    i++;
    orderLines.push(text);
  }

  return orderLines;
}
