export function greeting(): string {
  const hour = new Date().getHours();

  if (hour >= 5 && hour < 12) {
    return "Guten Morgen";
  } else if (hour >= 12 && hour < 18) {
    return "Guten Tag";
  } else {
    return "Guten Abend";
  }
}
