import { Customer } from "../interfaces/customer";
import { latestCustomerAddress, latestCustomerInsurance } from "./orderHelper";

type Measurement = {
  type: string;
  unit: string;
  value: number;
};

type Recommendation = {
  id: string;
  recommendations: string[];
};

type Address = {
  street: string;
  street_nr: string;
  zip: number;
  city: string;
};

type Patient = {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender?: number;
  veka: string | null;
  address: Address;
  language: string;
  phone: string;
  email: string;
  measurements: Measurement[];
  recommendations: Recommendation[];
};

type TakingTime = {
  doFrom: string;
  doTo: string | null;
};

type Dosage = {
  morning: string;
  noon: string;
  evening: string;
  night: string;
};

type Position = {
  dosage: Dosage | null;
  dateFrom: string;
  dateTo: string | null;
  inReserve: boolean | null;
  takingTime: TakingTime[];
};

type Medication = {
  id: string;
  idType: number;
  prescribedBy: string;
  routeOfAdministration: string;
  takingReason: string;
  unit: string;
  applicationInstructions: string | null;
  autoMedication: boolean | null;
  positions: Position[];
};

export type StructuredEmediplanData = {
  patient: Patient;
  medications: Medication[];
  author: string;
  date: string;
  id: string;
  medType: string;
  remarks: string;
};

function streetSplit(street: string | undefined, number: boolean) {
  if (street) {
    const splitted = street.split(" ");
    if (splitted.length > 1) {
      if (number) {
        return splitted.at(-1);
      } else {
        splitted.pop();
        return splitted.join(" ") ?? "";
      }
    } else {
      if (number) {
        return "";
      } else {
        return street ?? "";
      }
    }
  }
}

export function structureMedicationData(data: any): StructuredEmediplanData {
  return {
    patient: {
      firstName: data.Patient.FName || "",
      lastName: data.Patient.LName || "",
      birthDate: data.Patient.BDt || "",
      gender: data.Patient.Gender || "",
      veka: data.Patient.Ids?.length === 1 ? data.Patient.Ids[0].Val : null,
      address: {
        street: streetSplit(data.Patient.Street, false) ?? "",
        street_nr: streetSplit(data.Patient.Street, true) ?? "",
        zip: data.Patient.Zip || 0,
        city: data.Patient.City || "",
      },
      language: data.Patient.Lng || "",
      phone: data.Patient.Phone ?? "",
      email: data.Patient.Email ?? "",
      measurements:
        data.Patient.Med && data.Patient.Med.Meas
          ? data.Patient.Med.Meas.map(
              (meas: any): Measurement => ({
                type: meas.Type || "",
                unit: meas.Unit || "",
                value: meas.Val || 0,
              })
            )
          : [],
      recommendations:
        data.Patient.Med && data.Patient.Med.Rc
          ? data.Patient.Med.Rc.map(
              (rc: any): Recommendation => ({
                id: rc.Id || "",
                recommendations: rc.R ? rc.R : [],
              })
            )
          : [],
    },
    medications: data.Medicaments
      ? data.Medicaments.map(
          (med: any): Medication => ({
            id: med.Id || "",
            idType: med.IdType || 0,
            prescribedBy: med.PrscbBy || "",
            routeOfAdministration: med.Roa || "",
            takingReason: med.TkgRsn || "",
            unit: med.Unit || "",
            applicationInstructions: med.AppInstr || null,
            autoMedication: med.AutoMed || null,
            positions: med.Pos
              ? med.Pos.map(
                  (pos: any): Position => ({
                    dosage: pos.D
                      ? {
                          morning: pos.D[0] || "0",
                          noon: pos.D[1] || "0",
                          evening: pos.D[2] || "0",
                          night: pos.D[3] || "0",
                        }
                      : null,
                    dateFrom: pos.DtFrom || "",
                    dateTo: pos.DtTo || null,
                    inReserve: pos.InRes || null,
                    takingTime: pos.TT
                      ? pos.TT.map(
                          (tt: any): TakingTime => ({
                            doFrom: tt.DoFrom || "",
                            doTo: tt.DoTo || null,
                          })
                        )
                      : [],
                  })
                )
              : [],
          })
        )
      : [],
    author: data.Auth || "",
    date: data.Dt || "",
    id: data.Id || "",
    medType: data.MedType || "",
    remarks: data.Rmk || "",
  };
}

export function areProfilesDifferent(current: Customer, profileModel: any) {
  return (
    current.first_name !== profileModel?.first_name ||
    current.last_name !== profileModel?.last_name ||
    current.gender !== profileModel?.gender ||
    current.birthday !== profileModel?.birthday
  );
}
export function areAddressesDifferent(current: Customer, profileModel: any) {
  const address = latestCustomerAddress(current);
  if (!address) return true;

  return (
    address.zip?.toString() !== profileModel?.zip ||
    address.city !== profileModel?.city ||
    address.street !== profileModel?.street
  );
}

export function areInsurancesDifferent(current: Customer, profileModel: any) {
  const insurance = latestCustomerInsurance(current);

  if (!insurance) return true;
  return insurance.veka_nr !== profileModel?.veka;
}
export function areDifferentProfiles(current: Customer, profileModel: any) {
  return (
    areProfilesDifferent(current, profileModel) ||
    areInsurancesDifferent(current, profileModel) ||
    areAddressesDifferent(current, profileModel)
  );
}
