import { defineStore } from "pinia";
import { StructuredEmediplanData } from "../helpers/eMediplanHelper";
import { useWebshopStore } from "./webshopStore";
import { latestCustomerInsurance } from "../helpers/orderHelper";
import { Customer } from "../interfaces/customer";

export interface EmediplanReaderState {
  emediplanData: StructuredEmediplanData | undefined;
  profileModel?: Customer;
  newCustomerId?: string | null;
  medications?: any[];
}

export const useWebshopEmediplanStore = defineStore("webshopEmediplanStore", {
  state: (): EmediplanReaderState => ({
    emediplanData: undefined,
  }),
  getters: {
    currentCustomerData(state) {
      return useWebshopStore().customers?.find((c) => {
        return c.id === state.newCustomerId;
      });
    },
  },
  actions: {},
});
