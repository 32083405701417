import {
  bytesToHex,
  encryptAsClient,
  encryptAsServer,
  EncryptedResult,
} from "@/helpers/encryption";
import { Conversation } from "@/interfaces/conversation";
import { Message, MessageModelClass } from "@/interfaces/message";
import { useNotificationStore } from "@/store/notificationStore";
import { supabase } from "@/helpers/supabase";
import { usePublicStore } from "@/store/publicStore";
import { useSessionStore } from "@/store/sessionStore";

export async function encryptAndSaveMessage(
  conversation: Conversation | null,
  message: MessageModelClass | Message
) {
  const sessionStore = useSessionStore();
  const publicStore = usePublicStore();

  const selectedCareProviderGroup = sessionStore.selectedCareProviderGroup;
  if (selectedCareProviderGroup == null) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Kein Unternehmen gewählt. #or01`,
      type: "danger",
    });
    return;
  }
  if (conversation == null) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Keine Konversation gewählt. #or02`,
      type: "danger",
    });
    return;
  }
  if (message.decrypted_message == null) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Kein Inhalt in der Nachricht. #or03`,
      type: "danger",
    });
    return;
  }
  const clientPublicKey = publicStore.latestPublicKeyOfGroup(
    conversation.senderCareProviderGroup.id
  );
  const serverPublicKey = publicStore.latestPublicKeyOfGroup(
    conversation.receiverCareProviderGroup.id
  );

  const myPrivateKey = sessionStore.getCurrentPrivateKey();
  if (
    serverPublicKey?.keyId != myPrivateKey?.keyId &&
    clientPublicKey?.keyId != myPrivateKey?.keyId
  ) {
    useNotificationStore().notifications.push({
      msg: `Fehler - Keys sind nicht aktuell. Bitte aktualisieren Sie die Seite. #or04`,
      type: "danger",
    });
    return;
  }

  if (myPrivateKey) {
    if (
      clientPublicKey != null &&
      myPrivateKey != null &&
      serverPublicKey != null
    ) {
      let encryptedMsg: EncryptedResult | null = null;
      if (
        selectedCareProviderGroup.id == conversation.senderCareProviderGroup.id
      ) {
        encryptedMsg = await encryptAsClient(
          clientPublicKey,
          myPrivateKey,
          serverPublicKey,
          message.decrypted_message
        );
      } else {
        encryptedMsg = await encryptAsServer(
          serverPublicKey,
          myPrivateKey,
          clientPublicKey,
          message.decrypted_message
        );
      }

      const result = await supabase
        .from("orders_message")
        .insert({
          encrypted_message: bytesToHex(encryptedMsg.encryptedMsg),
          nonce: bytesToHex(encryptedMsg.nonce),
          sender_care_provider_group_id: selectedCareProviderGroup.id,
          conversation_id: conversation.id,
          id: message.id,
          type: message.type,
          server_key_id: encryptedMsg.serverKeyId,
          client_key_id: encryptedMsg.clientKeyId,
        })
        .select();

      if (result.error == null) {
        return;
      } else {
        if (result.status === 0) {
          useNotificationStore().notifications.push({
            msg: `Fehler - Keine Internetverbindung. #or10`,
            type: "danger",
          });
          throw new Error("Konnte nicht gespeichert werden");
        } else {
          useNotificationStore().notifications.push({
            msg: `Fehler - Ein unbekannter Datenbankfehler ist aufgetreten. #or11`,
            type: "danger",
          });
          throw new Error("Konnte nicht gespeichert werden");
        }
      }
    } else {
      useNotificationStore().notifications.push({
        msg: `Fehler - Kein Schlüssel vorhanden. #or05`,
        type: "danger",
      });
      console.error(
        "MISSING KEY...",
        clientPublicKey,
        myPrivateKey,
        serverPublicKey
      );
    }
  } else {
    useNotificationStore().notifications.push({
      msg: `Fehler - Keine privater Schlüssel vorhanden. #or06`,
      type: "danger",
    });
  }
}

export async function updateMessage(
  conversation: Conversation,
  oldMessage: Message,
  _changes: Map<string, any> | string
) {
  const newMessage = structuredClone(oldMessage);
  if (typeof _changes == "string") {
    newMessage.decrypted_message = _changes;
  } else {
    const changes = _changes as Map<string, any>;
    const jsonContent = JSON.parse(newMessage.decrypted_message ?? "{}");
    changes.forEach((value, key) => {
      jsonContent[key] = value;
    });
    newMessage.decrypted_message = JSON.stringify(jsonContent);
  }
  try {
    await encryptAndSaveMessage(conversation, newMessage);
  } catch (_) {
    console.error(_);
  }
}
