<template>
  <VueMultiselect
    :options="props.context.selectOptions"
    v-model="selectModel"
    :track-by="props.context.trackBy"
    :label="props.context.optionLabel"
    :allow-empty="props.context.allowEmpty ?? true"
    :value="selectModel"
    class="w-full rounded"
    :loading="props.context.loading ?? false"
    :disabled="props.context.disabled ?? false"
    :custom-label="props.context.customLabel"
    :max-height="150"
    :showNoOptions="false"
    :searchable="props.context.searchable"
    :options-limit="100"
    :multiple="props.context.multiple"
    :limit-text="limitText"
    :placeholder="props.context.placeholder"
    ref="multiselect"
    :class="{
      'multiselect__option--highlight': true,
    }"
  >
    <template v-slot:singleLabel="prop" v-if="props.context.optionLabel">
      <div class="flex justify-start items-center">
        <ProgressDot
          v-if="prop.option.progress !== undefined"
          :progress="prop.option.progress"
          class="mr-xs"
        />
        <span class="truncate">{{
          `${prop.option[props.context.optionLabel]}`.split("----")[0]
        }}</span>
      </div>
    </template>

    <template v-slot:option="prop" v-if="props.context.optionLabel">
      <div class="flex justify-start items-center">
        <ProgressDot
          v-if="prop.option.progress !== undefined"
          :progress="prop.option.progress"
          class="mr-s"
        />
        <span>{{
          `${prop.option[props.context.optionLabel]}`.split("----")[0]
        }}</span>
      </div>
      <small v-if="prop.option.desc !== undefined" class="mr-s">{{
        prop.option.desc
      }}</small>
    </template>

    <template v-slot:beforeList>
      <div
        @click="
          selectModel = [];
          $refs.multiselect.deactivate();
        "
        class="multiselect__option"
        v-if="props.context.reset"
      >
        {{ props.context.reset }}
      </div>
    </template>
    <template v-slot:afterList>
      <div
        @click="props.context.onAfterList()"
        class="multiselect__option"
        v-if="props.context.afterList && props.context.onAfterList"
      >
        {{ props.context.afterList }}
      </div>
    </template>
  </VueMultiselect>
</template>

<script setup>
import { computed, ref } from "vue";
import VueMultiselect from "vue-multiselect";
import ProgressDot from "../../components/ProgressDot.vue";

const props = defineProps({
  context: Object,
});

const selectModel = computed({
  get() {
    let value = props.context._value;
    if (
      props.context.optionLabel &&
      props.context.value &&
      !props.context.value[props.context.optionLabel] &&
      !props.context.multiple
    ) {
      value = props.context.selectOptions.find(
        (option) => props.context.value == option[props.context.trackBy]
      );
    } else {
      value = props.context.value;
    }
    return value;
  },
  set(option) {
    if (props.context.node) {
      props.context.node.input(option ?? 0);
    }
  },
});

function limitText(count) {
  return `und ${count} weitere`;
}
</script>
