export interface Customer {
  id?: string;
  created_at?: string;
  first_name?: string;
  last_name?: string;
  birthday?: string;
  gender?: number;
  veka?: string; // only for registration object
  zip?: number; // only for registration object
  city?: string; // only for registration object
  street?: string; // only for registration object
  street_nr?: string; // only for registration object
  address2?: string; // only for registration object
  email?: string;
  language?: string;
  webshop_customer_medication?: CustomerMedication[];
  webshop_customer_address?: CustomerAddress[];
  webshop_customer_insurance?: CustomerInsurance[];
  webshop_customer_questionnaire?: CustomerQuestionnaire[];
  webshop_customer_order_defaults?: CustomerOrderDefaults[];
}
export interface CustomerMedication {
  id: string;
  customer_id: string;
  created_at: string;
  expires_at: string;
  comment: string;
  medication_id: number;
  dosage: number[];
}

export interface CustomerOrderDefaults {
  id: number;
  generic: boolean;
  probon: boolean;
  substitute: boolean;
  created_at: string;
}
export interface CustomerAddress {
  id: number;
  customer_id: string;
  created_at: Date;
  zip?: number;
  city?: string;
  street?: string;
  street_nr?: string;
  address2?: string;
}

export interface CustomerInsurance {
  id: number;
  created_at: Date;
  insurance: string;
  police_nr: string;
  veka_nr: string;
  additional_insurance: string;
  additional_insurance_nr: string;
  customer_id: string;
}

export interface CustomerQuestionnaire {
  id: number;
  created_at: string;
  customer_id: string;
  weight: number;
  size: number;
  d_diabetes: boolean;
  d_high_bp: boolean;
  d_liver: boolean;
  d_blood_clotting: boolean;
  d_kidney: boolean;
  d_bronchi: boolean;
  d_others: string;
  a_penicillin: boolean;
  a_sulfonamide: boolean;
  a_asa: boolean;
  a_other: string;
  external_medications: number[];
}

export const genderOptions = [
  {
    value: "0",
    label: "Unb.",
  },
  {
    value: "1",
    label: "Männlich",
  },
  {
    value: "2",
    label: "Weiblich",
  },
  {
    value: "3",
    label: "Anderes",
  },
];

export const langOptions = {
  de: "Deutsch",
  fr: "Französisch",
};
