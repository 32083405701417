import {
  bytesToHex,
  generateRandomSalt,
  passwordToKeyPair,
} from "@/helpers/encryption";
import { supabase } from "@/helpers/supabase";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import _sodium from "libsodium-wrappers-sumo";

export interface EncryptionPasswordData {
  saltAsHex: string;
  publicKeyAsHex: string;
  opslimit: number;
  algorithm: number;
  memlimit: number;
}
export async function saveEncryptionPassword(
  password: string
): Promise<PostgrestSingleResponse<any[]>> {
  const pwData = await createEncryptionPassword(password);
  const result = await supabase
    .from("profile_publicKey")
    .insert({
      public_key: pwData.publicKeyAsHex,
      opslimit: pwData.opslimit,
      algorithm: pwData.algorithm,
      memlimit: pwData.memlimit,
      salt: pwData.saltAsHex,
    })
    .select();
  return result;
}

export async function createEncryptionPassword(
  password: string
): Promise<EncryptionPasswordData> {
  const salt = await generateRandomSalt();
  const saltAsHex = bytesToHex(salt);
  const keyPair = await passwordToKeyPair(password, saltAsHex);
  await _sodium.ready;
  const sodium = _sodium;
  return {
    publicKeyAsHex: bytesToHex(keyPair.publicKey),
    opslimit: sodium.crypto_pwhash_OPSLIMIT_MODERATE,
    algorithm: sodium.crypto_pwhash_ALG_DEFAULT,
    memlimit: sodium.crypto_pwhash_MEMLIMIT_MODERATE,
    saltAsHex: saltAsHex,
  };
}
