export function shortLocaleString(date: string) {
  return `${new Date(date).toLocaleString(undefined, {
    timeStyle: "short",
    dateStyle: "short",
  })}`;
}

export function shortLocaleStringFromDate(date: Date) {
  return `${date.toLocaleString(undefined, {
    timeStyle: "short",
    dateStyle: "short",
  })}`;
}

export function localeDateString(date: string) {
  const d = new Date(date);
  if (!(d instanceof Date) || isNaN(d.getTime())) {
    return "";
  }
  return `${d.toLocaleDateString(undefined, {
    dateStyle: "short",
  })}`;
}

export function localeDateStringFromDate(date: Date) {
  return `${date.toLocaleDateString(undefined, {
    dateStyle: "short",
  })}`;
}

export function ddmmyyyDate(date: string | undefined | null) {
  if (!date) return "";
  const d = new Date(date);
  if (!(d instanceof Date) || isNaN(d.getTime())) {
    return "";
  }
  return `${d.toLocaleDateString("en-GB").replaceAll("/", ".")}`;
}

export function upcomingDates(): {
  label: string;
  date: string;
  desc: string;
}[] {
  let currentDate = new Date();
  const options: { label: string; date: string; desc: string }[] = [];
  for (let i = 0; i < 365; i++) {
    const formattedDate = localeDateStringFromDate(currentDate);
    let label = formattedDate;
    if (i == 0) {
      label = "heute";
    } else if (i == 1) {
      label = "morgen";
    } else if (i <= 6) {
      label = `nächster ${getLocalizedWeekday(currentDate)}`;
    } else if (i <= 13) {
      label = `übernächster ${getLocalizedWeekday(currentDate)}`;
    }
    options.push({
      label: `${label}----${formattedDate}`,
      date: formatDate(currentDate),
      desc: formattedDate,
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  currentDate = new Date();
  for (let i = 1; i < 365; i++) {
    currentDate.setDate(currentDate.getDate() - 1);
    const formattedDate = localeDateStringFromDate(currentDate);

    options.push({
      label: `vor ${i} Tagen`,
      date: formatDate(currentDate),
      desc: formattedDate,
    });
  }
  return options;
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getLocalizedWeekday(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat(undefined, options).format(date);
}

export function getDateFromDaysSince1900(daysSince1900: number): Date {
  const millisecondsSince1900 = daysSince1900 * 24 * 60 * 60 * 1000;
  const millisecondsBetween1900And1970 = 25567 * 24 * 60 * 60 * 1000;
  const totalMillisecondsSince1970 =
    millisecondsSince1900 - millisecondsBetween1900And1970;
  const date = new Date(totalMillisecondsSince1970);

  return date;
}
