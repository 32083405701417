import { supabase } from "@/helpers/supabase";
import {
  bytesToHex,
  createServerSessionKey,
  decryptBytesAsServer,
  decryptSymmetric,
  encryptAsClient,
  encryptSymmetric,
  getSymmetricKey,
  hexToBytes,
  SymmetricEncryptionObj,
} from "@/helpers/encryption";
import {
  ManagedPatient,
  ManagedPatientDataPoint,
  ManagedPatientFileDeletion,
  ManagedPatientKey,
  ManagedPatientMedication,
  ManagedPatientProfile,
} from "@/interfaces/managedPatient";

import { defineStore } from "pinia";
import { useSessionStore } from "./sessionStore";
import { usePublicStore } from "./publicStore";
import { useNotificationStore } from "./notificationStore";
import { useMedicationsStore } from "./medicationsStore";

interface ManagedPatientsState {
  patientsMap: Map<number, ManagedPatient> | null;
  keys: Map<number, ManagedPatientKey> | null;
  connectedCareProviderGroups: Map<number, Set<number>> | null;
}

export const useManagedPatientsStore = defineStore("managedPatients", {
  state: (): ManagedPatientsState => ({
    patientsMap: null,
    keys: null,
    connectedCareProviderGroups: null,
  }),
  getters: {
    patients(state): ManagedPatient[] {
      return Array.from(state.patientsMap?.values() ?? []);
    },
  },
  actions: {
    async updateKeys(patientId?: number | undefined) {
      const publicStore = usePublicStore();
      const sessionStore = useSessionStore();
      let managedPatientKeysRequest = supabase
        .from("careProviderGroup_managedPatient")
        .select(
          `
          key_id,
          care_provider_group_id,
          managed_patient_id,
          created_by,
          nonce,
          encrypted_symmetric_key,          
          used_public_key_id,
          used_private_key_id
        `
        )
        .order("key_id", { ascending: false });

      if (patientId != undefined) {
        managedPatientKeysRequest = managedPatientKeysRequest.eq(
          "managed_patient_id",
          patientId
        );
      }

      const managedPatientKeysResult = await managedPatientKeysRequest.returns<
        ManagedPatientKey[]
      >();

      if (managedPatientKeysResult.data == null) {
        useNotificationStore().notifications.push({
          msg: `Fehler - Kein Patientenschlüssel gefunden. #mps01: ${managedPatientKeysResult.error}`,
          type: "danger",
        });
        return;
      }
      if (!sessionStore.selectedCareProviderGroup) {
        useNotificationStore().notifications.push({
          msg: `Fehler - kein Unternehmen gewählt. #mps02`,
          type: "danger",
        });
        return;
      }
      if (this.keys == null || patientId === undefined) {
        this.keys = new Map<number, ManagedPatientKey>();
      }
      if (this.connectedCareProviderGroups == null || patientId === undefined) {
        this.connectedCareProviderGroups = new Map<number, Set<number>>();
      }
      if (patientId !== undefined) {
        this.connectedCareProviderGroups.set(patientId, new Set<number>());
      }
      for (const key of managedPatientKeysResult.data) {
        if (!this.connectedCareProviderGroups.has(key.managed_patient_id)) {
          this.connectedCareProviderGroups.set(
            key.managed_patient_id,
            new Set<number>()
          );
        }
        const receiverPrivateKey =
          sessionStore.selectedCareProviderGroupKeys.get(
            key.used_public_key_id
          );
        const receiverPublicKey = publicStore.careProviderGroupKeys?.get(
          key.used_public_key_id
        );
        const senderKey = publicStore.careProviderGroupKeys?.get(
          key.used_private_key_id
        );
        this.connectedCareProviderGroups
          .get(key.managed_patient_id)
          ?.add(key.care_provider_group_id);

        if (
          key.care_provider_group_id !=
            sessionStore.selectedCareProviderGroup.id ||
          receiverPrivateKey == null ||
          receiverPublicKey == null ||
          senderKey == null ||
          this.keys?.has(key.key_id)
        ) {
          // only use keys of currently selected CPG
          // only decrypt new keys
          continue;
        }

        const serverSessionKey = await createServerSessionKey(
          receiverPublicKey.key,
          receiverPrivateKey.key,
          senderKey.key
        );

        key.decrypted_symmetric_key = await decryptBytesAsServer(
          serverSessionKey,
          hexToBytes(key.encrypted_symmetric_key),
          hexToBytes(key.nonce),
          false
        );
        this.keys?.set(key.key_id, key);
      }

      for (const key of this.connectedCareProviderGroups.keys()) {
        if (
          !this.connectedCareProviderGroups
            .get(key)
            ?.has(sessionStore.selectedCareProviderGroup.id)
        ) {
          this.connectedCareProviderGroups.delete(key);
        }
      }
    },
    async loadPatientHistoryOf(
      ofUser: number
    ): Promise<ManagedPatientDataPoint[]> {
      const history = this.patientsMap?.get(ofUser)?.history;
      if (history) {
        const dataPoints = history
          .filter((dataPoint) => dataPoint.type == 1)
          .sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          );
        return dataPoints;
      } else {
        return [];
      }
    },
    async loadPatientMedicationHistoryOf(
      ofUser: number
    ): Promise<ManagedPatientDataPoint[]> {
      const history = this.patientsMap?.get(ofUser)?.history;
      if (history) {
        const dataPoints = history
          .filter((dataPoint) => dataPoint.type == 2)
          .sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          );
        return dataPoints;
      } else {
        return [];
      }
    },

    async loadFileDeletions(
      ofUser: number
    ): Promise<ManagedPatientFileDeletion[]> {
      return (
        (
          await supabase
            .from("managedPatient_deletedFiles")
            .select(
              `
        deleted_by,
        created_at,
        managed_patient_id,
        data_id
          `
            )
            .eq("managed_patient_id", ofUser)
            .returns<ManagedPatientFileDeletion[]>()
        ).data ?? []
      );
    },

    async updatePatients(patientId?: number | undefined) {
      await this.updateKeys(patientId);
      let managedPatientsRequest = supabase.from("managedPatient").select(
        `
            id,
            owner_care_provider_group_id,
            history: managedPatient_data(
              *
            )
          `
      );
      if (patientId != undefined) {
        managedPatientsRequest = managedPatientsRequest.eq("id", patientId);
      }
      const sessionStore = useSessionStore();

      const managedPatientsResult = await managedPatientsRequest.returns<
        ManagedPatient[]
      >();

      const managedPatients = managedPatientsResult.data?.filter(
        (managedPatient) =>
          this.connectedCareProviderGroups
            ?.get(managedPatient.id)
            ?.has(sessionStore.selectedCareProviderGroup?.id ?? -1)
      );
      if (managedPatients) {
        for (const managedPatient of managedPatients) {
          await this.decryptPatientData(managedPatient);

          this.loadCurrentPatientData(managedPatient);
        }
        if (!this.patientsMap) this.patientsMap = new Map();
        managedPatients.forEach((p) => {
          if (p && p.currentProfile) this.patientsMap?.set(p.id, p);
        });
      } else {
        alert("ERROR LOADING PATIENTS!");
      }
    },
    loadCurrentPatientData(managedPatientData: ManagedPatient) {
      const profileDataPoint = findMostRecentDataPointInPatientHistory(
        managedPatientData,
        1
      );
      managedPatientData.currentProfile =
        profileDataPoint?.decrypted_data as ManagedPatientProfile;

      const medicationDataPoint = findMostRecentDataPointInPatientHistory(
        managedPatientData,
        2
      );
      managedPatientData.currentMedication =
        medicationDataPoint?.decrypted_data as Array<ManagedPatientMedication>;
    },
    async decryptPatientData(managedPatientData: ManagedPatient) {
      for (const dataPoint of managedPatientData.history) {
        const decryptedSymmetricKey = this.keys?.get(
          dataPoint.used_key_id
        )?.decrypted_symmetric_key;
        if (decryptedSymmetricKey) {
          try {
            const res = await decryptSymmetric(
              hexToBytes(dataPoint.encrypted_data),
              hexToBytes(dataPoint.nonce),
              decryptedSymmetricKey
            );
            dataPoint.decrypted_data = JSON.parse(res);
          } catch (e) {
            console.error(
              "ERROR ENCRYPT PATIENT",
              e,
              "KEY: ",
              decryptedSymmetricKey,
              "Nonce: ",
              dataPoint.nonce
            );
            return null;
          }
        } else {
          console.error(
            "FEHLER! KONNTE Patientendaten NICHT ENTSCHLüSSELN - ",
            managedPatientData.id,
            dataPoint.used_key_id,
            "Schlüssel",
            this.keys
          );
          return null;
        }
      }
    },
    async replaceManagedPatientKey(managedPatientId: number) {
      const sessionStore = useSessionStore();
      const publicStore = usePublicStore();

      if (sessionStore.selectedCareProviderGroup?.id == null) {
        alert("ERROR KEY CHANGE PATIENT 1 NO CPG SELECTED");
        return;
      }

      const connectedGroups =
        this.connectedCareProviderGroups?.get(managedPatientId);
      const publicKey = sessionStore.getCurrentPublicKey();
      const privateKey = sessionStore.getCurrentPrivateKey();

      if (!connectedGroups || !publicKey || !privateKey) {
        alert("FEHLER: Keine Unternehmen oder keys gefunden");
        return;
      }
      const newKeys = [];
      const cpgIds = [];
      const usedPublicKeys = [];
      const usedPrivateKeys = [];
      const nonces = [];
      for (const connectedGroup of connectedGroups) {
        const receiverPublicKey =
          publicStore.latestPublicKeyOfGroup(connectedGroup);
        if (!receiverPublicKey) {
          alert("Fehler! Kein Schlüssel gefunden.");
          return;
        }
        const symmetricKey = await getSymmetricKey();

        const encryptedSymmetricKey = await encryptAsClient(
          publicKey,
          privateKey,
          receiverPublicKey,
          symmetricKey
        );

        cpgIds.push(connectedGroup);
        newKeys.push(bytesToHex(encryptedSymmetricKey.encryptedMsg));
        nonces.push(bytesToHex(encryptedSymmetricKey.nonce));
        usedPublicKeys.push(receiverPublicKey.keyId);
        usedPrivateKeys.push(publicKey.keyId);
      }

      const updateKeyManagedPatientResult = await supabase.rpc(
        "update_managed_patient_keys",
        {
          _patient_id: managedPatientId,
          cpg_ids: cpgIds,
          new_keys: newKeys,
          nonce: nonces,
          used_public_key_ids: usedPublicKeys,
          used_private_key_ids: usedPrivateKeys,
        }
      );

      if (updateKeyManagedPatientResult.error) {
        alert("FEHLER BEIM ÄNdern des Keys DES PATIENTS.");
        return;
      }
    },
    async createManagedPatient(
      managedPatientProfile: any,
      careProviderGroupId: number,
      medications?: ManagedPatientMedication[]
    ) {
      const sessionStore = useSessionStore();
      if (sessionStore.selectedCareProviderGroup?.id == null) {
        alert("ERROR ADD PATIENT 1 NO CPG SELECTED");
        return;
      }

      const publicKey = sessionStore.getCurrentPublicKey();
      const privateKey = sessionStore.getCurrentPrivateKey();

      const symmetricKey = await getSymmetricKey();

      if (publicKey == null || privateKey == null) {
        alert("ERROR ADD PATIENT 2 NO VALID CPG KEYS!");
        return;
      }

      const encryptedSymmetricKey = await encryptAsClient(
        publicKey,
        privateKey,
        publicKey,
        symmetricKey
      );
      const encryptedProfileData = await encryptSymmetric(
        JSON.stringify(managedPatientProfile),
        symmetricKey
      );

      const receiverPublicKey =
        usePublicStore().latestPublicKeyOfGroup(careProviderGroupId);
      if (receiverPublicKey == null) return;

      let encryptedMedicationData: SymmetricEncryptionObj | null = null;
      if (medications !== undefined) {
        encryptedMedicationData = await encryptSymmetric(
          JSON.stringify(medications),
          symmetricKey
        );
      }

      const encryptedReceiverSymmetricKey = await encryptAsClient(
        publicKey,
        privateKey,
        receiverPublicKey,
        symmetricKey
      );

      const sqlArguments = {
        p_owner_care_provider_group_id:
          sessionStore.selectedCareProviderGroup.id,
        p_used_public_key_id: receiverPublicKey.keyId,
        p_used_private_key_id: privateKey.keyId,
        p_encrypted_profile_data: encryptedProfileData.encryptedMessage,
        p_profile_nonce: encryptedProfileData.nonce,
        p_encrypted_medications_data: encryptedMedicationData?.encryptedMessage,
        p_medications_nonce: encryptedMedicationData?.nonce,
        p_encrypted_symmetric_key: bytesToHex(
          encryptedSymmetricKey.encryptedMsg
        ),
        p_encrypted_symmetric_key_nonce: bytesToHex(
          encryptedSymmetricKey.nonce
        ),
        p_receiver_care_provider_group_id: careProviderGroupId,
        p_encrypted_receiver_symmetric_key: bytesToHex(
          encryptedReceiverSymmetricKey.encryptedMsg
        ),
        p_encrypted_receiver_symmetric_key_nonce: bytesToHex(
          encryptedReceiverSymmetricKey.nonce
        ),
      };

      const createManagedPatientResult = await supabase.rpc(
        "create_shared_managed_patient",
        sqlArguments
      );

      if (createManagedPatientResult.error) {
        useNotificationStore().notifications.push({
          msg: `Fehler beim Erstellen des Patients. Internetverbindung prüfen. #mps3${createManagedPatientResult.status}`,
          type: "success",
        });
        return;
      }
    },
    async setOwner(careProviderGroupId: number, patientId: number) {
      await supabase
        .from("managedPatient")
        .update([
          {
            owner_care_provider_group_id: careProviderGroupId,
            created_by: useSessionStore().session?.user.id,
          },
        ])
        .eq("id", patientId);
    },
    async fetchMedicationsInformation(managedPatientId: number) {
      const publicStore = usePublicStore();
      const medicationsStore = useMedicationsStore();

      if (!(this.connectedCareProviderGroups && this.patientsMap)) {
        return;
      }

      // Get connectedShopCareProviderGroups
      const connectedCPGIds =
        this.connectedCareProviderGroups?.get(managedPatientId);
      const connectedShopCareProviderGroups =
        publicStore.careProviderGroups?.filter((cpg) => {
          if (connectedCPGIds) {
            return connectedCPGIds.has(cpg.id) && cpg.is_shop;
          }
        });
      connectedShopCareProviderGroups?.sort((a, b) => {
        return a.group_name.localeCompare(b.group_name);
      });

      // Get patient medications
      const patientMedications =
        this.patientsMap.get(managedPatientId)?.currentMedication;

      if (!(connectedShopCareProviderGroups && patientMedications)) {
        return;
      }

      for (const shopCareProviderGroup of connectedShopCareProviderGroups) {
        const patientMedicationsForCpg = patientMedications.filter(
          (pm) => pm.supplierId === shopCareProviderGroup.id
        );
        await medicationsStore.fetchMedicationsInformation(
          patientMedicationsForCpg,
          shopCareProviderGroup.id
        );
      }
    },
  },
});

function findMostRecentDataPointInPatientHistory(
  managedPatient: ManagedPatient,
  type: number
): ManagedPatientDataPoint | null {
  if (managedPatient.history.length === 0) return null;
  const lastPoint = managedPatient.history.reduce((prev, current) => {
    if ((prev.type !== type || prev.id < current.id) && current.type === type) {
      return current;
    }
    return prev;
  });
  if (lastPoint.type == type) return lastPoint;
  return null;
}
