export const MedicationOrderStatus: Array<{
  id: number;
  progress: number;
  name: string;
}> = [
  { name: "Offen", id: 0, progress: 0 },
  { name: "in Bearbeitung", id: 1, progress: 1 },
  { name: "Vorbereitet", id: 2, progress: 2 },
  { name: "Kontrolliert", id: 5, progress: 3 },
  { name: "Storniert", id: 6, progress: 4 },
  { name: "Nota", id: 7, progress: 5 },
];

export const MedicationOrderBoxStatus: Array<{
  id: number;
  name: string;
  progress: number;
}> = [
  { name: "Offen", id: 0, progress: 0 },
  { name: "bereit zur Auslieferung", id: 4, progress: 1 },
  { name: "in Auslieferung", id: 1, progress: 2 },
  { name: "Ausgeliefert", id: 2, progress: 3 },
  { name: "Storniert", id: 3, progress: 4 },
];
