<template>
  <FormKit name="street" label="Strasse" type="text" validation="required" />
  <FormKit
    name="street_nr"
    label="Strassennr."
    type="text"
    validation="required"
  />
  <FormKit name="address2" label="zweite Adresszeile" type="text" />
  <FormKit name="zip" label="PLZ" type="text" validation="required" />
  <FormKit name="city" label="Ort" type="text" validation="required" />
</template>
<script setup lang="ts"></script>
